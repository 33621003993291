import { AnyAction } from 'redux';
import { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _has from 'lodash/has';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useAppSelector, useAppDispatch } from 'store';

const paramsToObject = (entries): Record<string, string> => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

interface Options {
  customParameter?: Record<string, URLSearchParams | string>;
  providerName?: string;
  arrayParams?: string[];
  formatParams?: (params: Record<string, string>) => Record<string, string>;
  defaultSearchParams?: Record<string, string>;
}

const useFetchList = (
  action?: (payload) => AnyAction,
  options?: Options,
): {
  searchParams: Record<string, string>;
  setSearch: (data: Record<string, unknown>, replace?: boolean) => void;
  list: Record<string, unknown>[];
  total: number;
  page: number;
  loading: boolean | any;
} => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { search, pathname } = location;
  const formattedSearch = search.replace(/^\?/, '');
  const urlParams = new URLSearchParams(formattedSearch);
  const entries = urlParams.entries();
  const searchParams = paramsToObject(entries);

  const providerName = _get(options, 'providerName', 'general');
  const customParameter = _get(options, 'customParameter');
  const formatParams = _get(options, 'formatParams');
  const defaultSearchParams = _get(options, 'defaultSearchParams');

  const list = useAppSelector((state) => state[providerName].list);
  const total = useAppSelector((state) => state[providerName].total);
  const page = useAppSelector((state) => state[providerName].page);
  const loading = useAppSelector((state) => state[providerName].loading);
  const formattedParams = formatParams ? formatParams(searchParams) : searchParams;

  const apiCall = useCallback(() => {
    if (action) {
      dispatch(
        action({
          ...formattedParams,
          page: parseInt(formattedParams.page),
          limit: parseInt(formattedParams.limit),
        }),
      );
    }
  }, [action, formattedParams, dispatch]);

  useEffect(() => {
    if (action) {
      let reHref = false; // define whether the url lack default parameters
      if (!_has(searchParams, 'page') || !_has(searchParams, 'limit')) {
        searchParams.page = searchParams.page || '1';
        searchParams.limit = searchParams.limit || '10';
        for (const key in defaultSearchParams) {
          searchParams[key] = defaultSearchParams[key];
        }
        reHref = true;
      }
      if (!_isEmpty(customParameter)) {
        for (const key in customParameter) {
          if (searchParams[key] !== customParameter[key].toString()) {
            searchParams[key] = customParameter[key].toString();
            reHref = true;
          }
        }
      }
      if (reHref) {
        history.replace(`${pathname}?${new URLSearchParams(searchParams).toString()}`);
        return;
      }
      apiCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const setSearch = (data, replace = false) => {
    const params = { ...data };
    if (!_has(params, 'page') || !_has(params, 'limit')) {
      params.page = data.page || 1;
      params.limit = data.limit || 10;
    }
    Object.keys(params).forEach((key) =>
      [null, undefined, ''].includes(params[key]) ? delete params[key] : {},
    );
    const querystring = new URLSearchParams(params).toString();
    if (replace) {
      history.replace(`${pathname}?${querystring}`);
    } else {
      history.push(`${pathname}?${querystring}`);
    }
  };
  return {
    searchParams: formattedParams,
    setSearch,
    list,
    total,
    page,
    loading,
  };
};
export default useFetchList;
