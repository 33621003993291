import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import { t } from 'i18next';
import i18n from 'i18n';
import Helpers from 'core/utils/helpers';
import { DETAIL_SIMULATION_STATUS } from 'utils/constants';
import { getProjectDetailRequest } from 'providers/ProjectProvider/slice';
import Helper from 'utils/helpers';
import {
  getSimulationListRequest,
  getSimulationListSuccess,
  getSimulationListFailure,
  getSimulationDetailRequest,
  getSimulationDetailSuccess,
  getSimulationDetailFailure,
  getOtherSimulationListRequest,
  getOtherSimulationListSuccess,
  downLoadFileRequest,
  updateApproximateSimulationRequest,
  updateApproximateSimulationSuccess,
  updateApproximateSimulationFailure,
  updateDetailSimulationRequest,
  updateDetailSimulationSuccess,
  updateDetailSimulationFailure,
  publishDetailSimulationRequest,
  publishDetailSimulationSuccess,
  publishDetailSimulationFailure,
  getCategoriesListRequest,
  getCategoriesListSuccess,
  updateSimulationRequest,
  updateSimulationSuccess,
  updateSimulationFailure,
  quickEditSimulationRequest,
  quickEditSimulationSuccess,
  quickEditSimulationFailure,
  deleteSimulationRequest,
  getPrefectureListRequest,
  getPrefectureListSuccess,
  getCityListRequest,
  getCityListSuccess,
  getPrefectureListFailure,
  requestSimulationRequestSuccess,
  requestSimulationRequest,
  sendInformationRequest,
  updateMemosSuccess,
  updateMemosRequest,
  updateMemosFailure,
} from './slice';
import Service from './service';

export function* getSimulationList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, [
      'page',
      'limit',
      'sort',
      'keyword',
      'status[]',
      'createdAtFrom',
      'createdAtTo',
      'businessStatus',
      'operatorId',
      'priority',
    ]);
    const res = yield Service.list(params);
    yield put(getSimulationListSuccess(res.data));
  } catch (error) {
    yield put(getSimulationListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getSimulationDetail(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.detail(payload.id, {});
    yield put(getSimulationDetailSuccess(res.data));
  } catch (error) {
    yield put(getSimulationDetailFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getOtherSimulationList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.getOtherSimulations(payload.id, {});
    yield put(getOtherSimulationListSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* downLoadFile(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['key']);
    const res = yield Service.getDownLoadFile(payload.id, params);

    if (Helper.isIphone()) {
      window.location.assign(_get(res, 'data.result'));
    } else {
      const link = document.createElement('a');
      link.href = _get(res, 'data.result');
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateApproximateSimulation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, [
      'recommendLevelFlag',
      'numberOfTable',
      'numOfSeats',
      'occupancyRate',
      'tableTurnover',
      'businessHours',
      'businessDays',
      'cost',
      'personnelExpenses',
      'rent',
      'otherExpenses',
      'propertyAcquisitionCost',
      'deposit',
      'franchiseFee',
      'openingFee',
      'interiorEquipmentFee',
      'kitchenEquipmentFee',
      'paymentTerminalFee',
      'floorSpace',
    ]);
    yield Service.updateApproximateSimulation(payload.id, params);
    yield put(updateApproximateSimulationSuccess());
    if (payload.isPublish) {
      yield Service.publishApproximateSimulation(payload.id);
      yield put(getSimulationDetailRequest({ id: payload.id }));
    }
    Helpers.toastr('', i18n.t('globally:saved'), 'success');
  } catch (error) {
    yield put(updateApproximateSimulationFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateDetailSimulation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, [
      'recommendLevelFlag',
      'numberOfTable',
      'numOfSeats',
      'occupancyRate',
      'tableTurnover',
      'businessHours',
      'businessDays',
      'cost',
      'personnelExpenses',
      'rent',
      'otherExpenses',
      'propertyAcquisitionCost',
      'deposit',
      'franchiseFee',
      'openingFee',
      'interiorEquipmentFee',
      'kitchenEquipmentFee',
      'paymentTerminalFee',
      'floorSpace',
    ]);
    yield Service.updateDetailSimulation(payload.id, params);
    yield put(updateDetailSimulationSuccess());

    if (payload.isPublish) {
      yield Service.publishDetailSimulation(payload.id, {
        action: DETAIL_SIMULATION_STATUS.PUBLISHED,
      });
      yield put(getSimulationDetailRequest({ id: payload.id }));
    }

    Helpers.toastr('', i18n.t('globally:saved'), 'success');
  } catch (error) {
    yield put(updateDetailSimulationFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* publishDetailSimulation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['action']);
    yield Service.publishDetailSimulation(payload.id, params);
    yield put(getSimulationDetailRequest({ id: payload.id }));
    yield put(publishDetailSimulationSuccess());
    Helpers.toastr('', i18n.t('globally:successfully'), 'success');
  } catch (error) {
    yield put(publishDetailSimulationFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getCategoriesList(): unknown {
  try {
    const res = yield Service.getCategoriesList();
    yield put(getCategoriesListSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateSimulation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, [
      'customerName',
      'customerCompany',
      'projectName',
      'categoryId',
      'operatorId',
      'expectAverageUnitPrice',
      'address',
      'files',
      'floorSpace',
      'prefecture',
      'city',
    ]);
    yield Service.updateSimulation(payload.id, params);
    yield put(updateSimulationSuccess());
    yield put(getSimulationDetailRequest({ id: payload.id }));
    if (payload.projectId) {
      yield put(getProjectDetailRequest({ id: payload.projectId }));
    }
    Helpers.toastr('', i18n.t('globally:saved'), 'success');
  } catch (error) {
    yield put(updateSimulationFailure(error.message));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* quickEditSimulation(action: AnyAction): unknown {
  try {
    const { queryParams, id, ...params } = action.payload;
    yield Service.quickEditSimulation(id, params);
    yield put(quickEditSimulationSuccess());
    yield put(getSimulationListRequest(queryParams));
    if (params.projectId) {
      yield put(getProjectDetailRequest({ id: params.projectId }));
    }
    Helpers.toastr('', i18n.t('globally:saved'), 'success');
  } catch (error) {
    yield put(quickEditSimulationFailure(error.message));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* deleteSimulation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    yield Service.deleteSimulation(payload.id);
    yield put(getSimulationListRequest(payload.searchParams));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getPrefectureList(): unknown {
  try {
    const res = yield Service.getPrefectureList();
    yield put(getPrefectureListSuccess(res.data));
  } catch (error) {
    yield put(getPrefectureListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getCityList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.getCityList(payload);
    yield put(getCityListSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* createSimulationRequest(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.requestRoiSimulation(payload);
    yield put(requestSimulationRequestSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* sendInformation(action: AnyAction): unknown {
  try {
    const { payload } = action;
    yield Service.sendInformation(payload);
    Helpers.toastr('', t('simulation:sendInformationSuccess'));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateMemos(action: AnyAction): unknown {
  try {
    const { queryParams, id, ...params } = action.payload;
    yield Service.updateMemosApi(id, params);
    Helpers.toastr('', t('simulation:updateSuccess'), 'success');
    yield put(getSimulationListRequest(queryParams));
    yield put(getSimulationDetailRequest({ id }));
    yield put(updateMemosSuccess());
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
    yield put(updateMemosFailure());
  }
}

export default function* simulationWatch(): Generator {
  yield takeEvery(getSimulationListRequest.type, getSimulationList);
  yield takeEvery(getSimulationDetailRequest.type, getSimulationDetail);
  yield takeEvery(getOtherSimulationListRequest.type, getOtherSimulationList);
  yield takeLeading(downLoadFileRequest.type, downLoadFile);
  yield takeLeading(updateApproximateSimulationRequest.type, updateApproximateSimulation);
  yield takeLeading(updateDetailSimulationRequest.type, updateDetailSimulation);
  yield takeLeading(publishDetailSimulationRequest.type, publishDetailSimulation);
  yield takeLeading(getCategoriesListRequest.type, getCategoriesList);
  yield takeLeading(updateSimulationRequest.type, updateSimulation);
  yield takeLeading(quickEditSimulationRequest.type, quickEditSimulation);
  yield takeLeading(deleteSimulationRequest.type, deleteSimulation);
  yield takeLeading(getPrefectureListRequest.type, getPrefectureList);
  yield takeLeading(getCityListRequest.type, getCityList);
  yield takeLeading(requestSimulationRequest.type, createSimulationRequest);
  yield takeLeading(sendInformationRequest.type, sendInformation);
  yield takeLeading(updateMemosRequest.type, updateMemos);
}
