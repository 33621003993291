import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';
import _isNil from 'lodash/isNil';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import Helpers from 'core/utils/helpers';
import i18n from 'i18n';
import { getSimulationDetailRequest } from 'providers/SimulationProvider/slice';
import Helper from 'utils/helpers';
import {
  addProjectRequest,
  addProjectSuccess,
  addProjectFailure,
  getProjectListRequest,
  getProjectListSuccess,
  getProjectListFailure,
  getProjectDetailRequest,
  getProjectDetailSuccess,
  getProjectDetailFailure,
  updateDirectionCostRequest,
  updateDirectionCostSuccess,
  updateDirectionCostFailure,
  changeProjectStatusRequest,
  changeProjectStatusSuccess,
  changeProjectStatusFailure,
  addFileToProjectRequest,
  removeFileFromProjectRequest,
  updateCommentRequest,
  updateCommentSuccess,
  updateCommentFailure,
  uploadFileLoadingFailure,
  uploadFileLoadingSuccess,
  removeFileFromProjectFailure,
  downLoadFileProjectRequest,
} from './slice';
import Service from './service';

export function* getProjectList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit', 'keyword', 'progressFlag']);
    const res = yield Service.list(params);
    yield put(getProjectListSuccess(res.data));
  } catch (error) {
    yield put(getProjectListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* addProject(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pickBy(
      _pick(payload, [
        'requestId',
        'companyId',
        'userId',
        'projectName',
        'numOfSeats',
        'designFee',
        'interiorEquipmentFee',
        'kitchenEquipmentFee',
      ]),
      function notNull(value) {
        return !_isNil(value);
      },
    );
    const res = yield Service.add(params);
    yield put(addProjectSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:createSuccess'), 'success');
    yield put(push(`/projects/${res.data._id}`));
  } catch (error) {
    yield put(addProjectFailure({ error }));
    Helpers.toastr('', error.message, 'error');
  }
}
export function* getProjectDetail(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.detail(payload.id, {});
    yield put(getProjectDetailSuccess(res.data));
  } catch (error) {
    yield put(getProjectDetailFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateDirectionCost(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pickBy(
      _pick(payload, [
        'companyId',
        'userId',
        'projectName',
        'numOfSeats',
        'designFee',
        'interiorEquipmentFee',
        'kitchenEquipmentFee',
      ]),
      function notNull(value) {
        return !_isNil(value);
      },
    );
    yield Service.updateDirectionCost(payload.id, params);
    yield put(updateDirectionCostSuccess());
    yield put(getProjectDetailRequest({ id: payload.id }));
    if (payload.simulationRequestId) {
      yield put(getSimulationDetailRequest({ id: payload.simulationRequestId }));
    }
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
  } catch (error) {
    yield put(updateDirectionCostFailure(error.message));
    Helpers.toastr('', error.message, 'error');
  }
}
export function* changeProjectStatus(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['progressFlagsOfProject']);
    yield Service.changeStatus(payload.id, params);
    yield put(getProjectDetailRequest({ id: payload.id }));
    yield put(changeProjectStatusSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
  } catch (error) {
    yield put(changeProjectStatusFailure(error.message));
    Helpers.toastr('', error.message, 'error', 10000);
  }
}

export function* addFileToProject(action: AnyAction): unknown {
  const { payload } = action;
  const params = _pick(payload, ['type', 'keys']);
  try {
    yield Service.addFileToProject(payload.id, params);
    yield put(uploadFileLoadingSuccess({ type: params.type }));
    yield put(getProjectDetailRequest({ id: payload.id }));
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
  } catch (error) {
    yield put(uploadFileLoadingFailure({ type: params.type }));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* removeFileFromProject(action: AnyAction): unknown {
  const { payload } = action;
  const params = _pick(payload, ['type', 'key']);
  try {
    yield Service.removeFileFromProject(payload.id, params);
    yield put(getProjectDetailRequest({ id: payload.id }));
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
  } catch (error) {
    yield put(removeFileFromProjectFailure(params));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* updateComment(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['comment']);
    yield Service.updateComment(payload.id, params);
    yield put(updateCommentSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
  } catch (error) {
    yield put(updateCommentFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* downLoadFile(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['key', 'type']);
    const res = yield Service.getDownLoadFile(payload.id, params);
    if (Helper.isIphone()) {
      window.location.assign(_get(res, 'data.result'));
    } else {
      const link = document.createElement('a');
      link.href = _get(res, 'data.result');
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* projectWatch(): Generator {
  yield takeLeading(addProjectRequest.type, addProject);
  yield takeEvery(getProjectListRequest.type, getProjectList);
  yield takeEvery(getProjectDetailRequest.type, getProjectDetail);
  yield takeLeading(updateDirectionCostRequest.type, updateDirectionCost);
  yield takeLeading(changeProjectStatusRequest.type, changeProjectStatus);
  yield takeEvery(addFileToProjectRequest.type, addFileToProject);
  yield takeEvery(removeFileFromProjectRequest.type, removeFileFromProject);
  yield takeLeading(updateCommentRequest.type, updateComment);
  yield takeLeading(downLoadFileProjectRequest.type, downLoadFile);
}
