import translateJp from 'core/i18n/resources/jp';

const TRANSLATIONS_JP = {
  ...translateJp,
  globally: {
    send: '送信する',
    successfully: '無事',
    register: '登録する',
    create: '新規作成',
    update: '更新',
    deleteSuccessfully: '正常に削除されました',
    delete: '刪除',
    edit: '編集',
    createdAt: '登録日',
    pleaseSelect: '選択してください',
    pleaseEnter: '入力してください',
    save: '保存する',
    keep: '保存',
    warningChangeRoute: '入力データは保存されていませんが、このままページを離脱しますか？',
    saved: '保存しました',
    addFile: 'ファイルを追加',
    closeUp: '閉じる',
    selectFiles: 'ファイルを選択',
    doYouWantToDelete: '本当に削除しますか？',
    no: 'なし',
    yes: 'あり',
    add: '追加',
    networkError: 'インターネット接続がありません。',
    somethingWentWrong: 'ページデータが取得できません。',
    editSuccessfully: '完了しました。',
    createSuccessfully: '完了しました。',
  },
  login: {
    email: 'メールアドレス',
    password: 'パスワード',
    forgotPassword: 'パスワードをお忘れの方はこちら',
    login: 'ログイン',
  },
  resetPassword: {
    anEmailHasBeenSent:
      '案内メールがあなたのメールアドレスに送信されました（迷惑メールフォルダーもご確認をお願い致します）。',
    confirmPassword: 'パスワード（確認）',
  },
  validation: {
    required: 'この項目は入力必須です。',
    invalidFormat: '無効な形式です。',
    least8Characters: '8文字以上必要です',
    confirmPassword: 'パスワードが一致しません。',
    invalidNumber: '無効な番号',
    mostCharacters: 'これは最大{{length}}文字である必要があります',
    minLessMax: '最小値は最大値よりも小さくする必要があります',
    invalidEmail: 'このメールアドレスは無効です',
    invalidUpload: '{{fileName}}は（PNG、JPG、HEIC）ファイルではありません',
    passwordRule: 'パスワードは6文字〜12文字で入力してください。',
  },
  adminRoles: {
    admin: '管理者',
    editor: '編集者',
  },
  confirmModal: {
    deactivateAccount: 'このアカウントを無効にしますか？',
    deleteItem: 'このアイテムを削除しますか？',
    doYouWantToRegister: 'この内容で登録しますか？',
    doYouWantToRegisterUpdate: 'この内容で更新しますか？',
    activateAccount: 'このアカウントを有効にしますか?',
  },
  adminUser: {
    editWithoutPassword: '※空欄の場合パスワードは更新されません',
    changedStatusSuccessfully: '更新成功',
    createNewAdmin: '新規管理者登録',
    editAdminAccount: '管理者情報編集',
  },
  menuBarCustom: {
    company: 'ディレクション会社',
    adminUser: '管理アカウント',
    simulation: '無料相談依頼',
    message: 'メッセージ',
    freeConsultation: 'お問い合わせ',
    news: 'お知らせ管理',
  },
  company: {
    createInfo: 'ディレクション会社登録',
    name: '会社名',
    editInfo: 'ディレクション社編集',
    numberOfProject: '案件数',
    numberOfUser: 'アカウント数',
    accountManagement: 'アカウント管理',
  },
  columnTitleCustom: {
    role: '権限',
    representative: '代表',
    tel: '電話番号',
  },
  user: {
    representativeFlag: '代表者フラグ',
    notRepresentative: '代表ではない',
    name: '名前',
    accountRegistration: 'ディレクション会社アカウント登録',
    accountUpdating: '方向会社アカウントの更新',
    directionCompanyAccount: 'ディレクション会社アカウント',
  },
  simulation: {
    status: 'ステータス',
    createdAt: '依頼日',
    projectName: '案件名',
    verification: '確認',
    header: '無料相談依頼',
    headerDetail: '無料相談依頼',
    requestInformation: '依頼者情報',
    requesterName: '依頼者氏名',
    personInCharge: 'ミセツク担当者',
    editBasicInfo: '基本情報編集',
    message: 'メッセージ',
    investmentAmount: '投資金額シミュレーション条件',
    businessType: '業態',
    assumedPrice: '想定客単価',
    address: '物件住所',
    attachment: '添付ファイル',
    otherSimulations: '過去の投資金額シミュレーション事例',
    approximateSimulation: '概算シミュレーション',
    detailedSimulation: '詳細シミュレーション',
    unregistered: '未登録',
    publishToRestaurant: '飲食店事業者に公開',
    recommendationLevel: '開業おすすめ度',
    monthlySale: '月間売上',
    monthlyProfit: '月間利益',
    storeInteriorCost: '店舗内装費用',
    overview: '概要',
    assumedStorePL: '想定店舗PL',
    earnings: '売上',
    customerUnitPrice: '客単価',
    numberOfCustomers: '客数',
    numberOfTables: '卓数',
    numOfSeats: '席数',
    occupancyRate: '満席率',
    tableTurnover: '回転数',
    businessHours: '営業時間',
    businessDays: '営業日数',
    table: '卓',
    seat: '席',
    times: '回',
    time: '時間',
    day: '日',
    runningCost: 'ランニングコスト',
    cost: '原価',
    personnelExpenses: '人件費',
    rent: '賃料',
    otherExpenses: '他経費',
    operatingIncome: '営業利益',
    assumedInitialCost: '想定初期コスト',
    propertyAcquisitionCost: '物件取得費',
    deposit: '保証金',
    franchiseFee: '加盟金',
    openingFee: '開業費',
    interiorEquipmentFee: '内装設備費',
    kitchenEquipmentFee: '厨房機器',
    paymentTerminalFee: '決済端末',
    paybackPeriod: '投資回収期間',
    publishedToRestaurant: '飲食店事業者に公開済み',
    unpublished: '非公開に戻す',
    sendReportToRestaurantOwner: '飲食店 事業者に公開',
    areYouSureToPublish:
      'この概算シミュレーションを飲食店事業者様に公開しますか？併せて詳細シミュレーションに各値をコピーします。',
    makeAProject: '案件化する',
    areYouSurePublishDetail: 'この詳細シミュレーションを飲食店事業者様に公開しますか？',
    areYouSureUnpublishDetail: 'この詳細シミュレーションを飲食店事業者様から非公開にしますか？',
    haventSentSimpleROIFirst:
      'まだ概算シミュレーションが飲食店事業者に公開されていないため、詳細シミュレーションフォームの編集はできません',
    customerName: '依赖者名',
    customerCompany: '会社名',
    categoryId: '業態',
    maximumFileAttach: '添付できるファイルは最大10個です。',
    maximumSizeUpload: '{{size}}以内のファイルを指定してください。',
    floorSpace: '坪数',
    canNotDelete: 'この問い合わせはすでに案件化されているため、削除することはできません',
    showDeletedData: '削除済みデータも表示',
    floorSpaceUnit: '坪',
    requestSimulation: '無料シミュレーション',
    choosePrefecture: '都道府県を選択してください',
    chooseCity: '未定',
    selectBusinessType: '業態を選択してください',
    estimatedUnitPrice: '想定客単価',
    telephone: '電話番号',
    email: 'メールアドレス',
    registration: '登録',
    newUserCreated: '新しいユーザー情報が作成されました。',
    password: 'パスワード',
    dontForget: '※忘れないようにメモをしておいてください。',
    passwordNote:
      'パスワードを忘れた場合は https://misetsuku-uat.scrum-dev.com/reset-password からパスワード変更処理を行ってください',
    priority: '優先度',
    businessStatus: '受注ステータス',
    prefecture: '都道府県',
    timeRangeForCall: '連絡がつながる時間',
    companyName: '法人名',
    category: '業態',
    latestCallDate: '最新架電日',
    operatorName: 'ミセツク担当',
    firstCallDate: '初回架電日',
    callConnectedDate: '通電日',
    interviewDate: '面談日',
    requesterNameKana: '依頼者氏名（カナ）',
    customerCategory: '法人/個人',
    openingDate: '出店の予定月',
    requiredCapital: '開店予定資金',
    propertyContractFlag: '物件契約',
    designerConstructionContract: '施工会社・デザイナー契約',
    businessPlanFlag: '事業計画',
    preparingFunds: '資金の準備',
    experienceFlag: '開業経験',
    inquiryNeeds: '問い合わせ時ニーズ',
    appealPoint1: '架電時刺さった訴求1',
    appealPoint2: '架電時刺さった訴求2',
    age: '年齢',
    gender: '性別',
    contractDate: '成約日',
    meetingNeeds: '商談時ニーズ',
    orderDecidingFactor: '受注決め手',
    accessSource: '流入元',
    accessSourceDetail: '流入元詳細',
    openingDateCheckbox: 'まだ決まっていない',
    quickEdit: 'クイック編集',
    year: '年',
    month: '月',
    send: '送信',
    sendInformation: 'アカウント情報再送',
    sendInfoConfirmMessage: `{{name}}様宛にマイページへのログイン情報をメールで送ります。
      これまで使用していたパスワードはリセットされます。
      問題なければ送信を押してください。`,
    sendInformationSuccess: 'ログイン情報を送信しました。',
    memo: 'メモ',
    memoList: 'メモ一覧',
    updateSuccess: '完了しました。',
    exceedLimitation: '{{n}}文字以内で入力してください。',
    addMemo: 'メモを追加する',
  },
  simulationStatus: {
    UNDER_SIMPLE_ROI: '概算シミュレーション中',
    COMPLETE_SIMPLE_ROI: '概算シミュレーション完了',
    UNDER_DETAIL_ROI: '詳細シミュレーション中',
    COMPLETE_DETAIL_ROI: '詳細シミュレーション完了',
  },
  project: {
    newProjectRegistration: '新規案件登録',
    investmentAmount: '投資金額シミュレーション情報',
    linkToInvestmentAmount: '投資金額シミュレーションへのリンク',
    companyInfo: 'ディレクション会社情報',
    haventPickPresentative:
      'このディレクション会社には代表者が設定されていません。ディレクション会社メニューから代表者を設定してください',
    designFee: 'デザイン費',
    projectName: '案件名',
    menu: '施工案件',
    headerList: '案件管理',
    operator: '担当者氏名',
    toProjectDetail: '案件詳細ページへ',
    projectInfo: '案件情報',
    directionUser: 'ディレクション',
    restaurantOwner: '飲食店',
    directionCompanyName: 'ディレクション会社名',
    kitchenEquipmentFee: '厨房機器費',
    budgetEditing: 'ディレクション会社・各種予算編集',
    storeDevelopmentQuotationContract: '店舖開発見積/契約',
    storeDevelopment: '店舖開発',
    remarks: '備考',
    subtextComment: 'ユーザーに公開される情報です',
    enterYourComment: 'コメントを記入してください',
    restaurantAndDirection: '飲食店・ディレクション',
  },
  projectStatus: {
    STORE_DEVELOPMENT_CONTRACT: '店舗開発見積/契約',
    STORE_DEVELOPMENT: '店舗開発',
    DESIGN_CONTRACT: 'デザイン見積/契約',
    DESIGN: 'デザインプラン',
    CONSTRUCTION_CONTRACT: '内装工事見積/契約',
    CONSTRUCTION: '施工',
    COMPLETE_CONTRACT: '引き渡し',
    COMPLETE: '完了',
  },
  projectFileType: {
    SCHEDULE_WHOLE: '全体スケジュール',
    QUOTATION_STORE_DEVELOPMENT: '店舗開発見積書',
    CONTRACT_STORE_DEVELOPMENT: '店舗開発契約書',
    QUESTIONNAIRE_STORE_DEVELOPMENT: 'ヒアリングシート',
    SKELTON_STORE_DEVELOPMENT: '躯体図',
    MASTERPLAN_STORE_DEVELOPMENT: 'マスタープラン',
    QUOTATION_DESIGN_CONTRACT: 'デザインプラン見積書',
    CONTRACT_DESIGN_CONTRACT: 'デザインプラン注文書',
    INVOICE_DESIGN_CONTRACT: 'デザインプラン請求書',
    SCHEDULE_DESIGN: 'スケジュール',
    BASIC_DIAGRAM_DESIGN: '基本図',
    DESIGN_DESIGN: 'デザイン',
    QUOTATION_CONSTRUCTION_CONTRACT: '内装工事見積書',
    CONTRACT_CONSTRUCTION_CONTRACT: '内装工事注文書',
    INVOICE_CONSTRUCTION_CONTRACT: '内装工事請求書',
    SCHEDULE_CONSTRUCTION: '施工スケジュール',
    DOCS_COMPLETE: '引き渡し書類',
  },
  message: {
    roomParticipant: 'ルーム参加者',
    send: '送信',
    attached: '添付',
    title: 'メッセージ',
    notifyByEmail: 'メールで通知する',
    you: 'あなた',
    delete: '削除',
  },
  timeStamp: {
    justNow: 'たった今',
    minutesAgo: '分前',
    hoursAgo: '時間前',
    daysAgo: '日前',
    weeksAgo: '週間前',
    monthsAgo: 'か月前',
    year: '{year}年',
  },
  roomType: {
    AR: 'クライアント',
    AD: 'ディレクション会社',
    ARD: 'クライアント・ディレクション会社',
  },
  consultation: {
    name: '名前',
    email: 'メールアドレス',
    consultationDetails: 'ご相談内容',
    goToDetail: '確認',
    delete: '削除',
    header: 'お問い合わせ',
    detailHeader: '無料相談詳細',
    status: 'ステータス',
    createdAt: '日時',
  },
  news: {
    delete: '削除',
    createNewsHeader: 'お知らせ入力',
    title: 'タイトル',
    content: '内容',
    publishTime: '公開日時',
  },
  customerCategory: {
    CORPORATION: '法人',
    INDIVIDUAL: '個人',
  },
  designerConstructionContract: {
    CONSTRUCTION_FIXED: '施工会社決定',
    UNDER_ESTIMATION: '見積もり依頼中',
    DESIGNER_FIXED: 'デザイナー決定',
    NONE: 'なし',
  },
  priority: {
    S: 'S - 契約締結 -',
    A: 'A - 短期：有効 -',
    B: 'B - 長期有効 -',
    C: 'C - 短期：無効（連絡つかない）-',
    D: 'D - 長期無効（今じゃない）-',
  },
  businessStatus: {
    CALL_NOT_CONNECTED: '架電時：連絡つかない',
    CALL_NO_NEEDS: '架電時：ニーズなし',
    AFTER_CALL_NOT_CONNECTED: '架電後：連絡つかない',
    NEW_MEETING_SCHEDULED: '新規面談日程調整中',
    NEW_MEETING_DECIDED: '新規面談日決定',
    AFTER_MEETING_NOT_CONNECTED: '面談後連絡つかない',
    NEW_MEETING_DONE: '新規面談済',
    CONTRACT_NEGOTIATION: '契約商談中',
    REVIVAL_NEGOTIATION_WAITING: '復活商談待機',
    REVIVAL_MEETING_DONE: '復活面談済',
    STORE_DEVELOPMENT_CONTRACT: '店舗開発契約',
    LICENSE_CONTRACT: 'ライセンス契約',
  },
  inquiryNeed: {
    FIND_INTERIOR_COMPANY: '内装会社探し',
    PROPERTY_SELECTION_ASSISTANCE: '物件選び手伝い',
    PROPERTY_INTRODUCTION: '物件紹介',
    RESTAURANT_STARTUP_GENERAL: '飲食開業全般',
    FUND_RAISING: '資金調達',
  },
  appealPoint: {
    FULL_SUPPORT_BEFORE_OPENING: '開業前まるっと５万',
    MAKINO_RECORDS: '牧野さん実績',
    CHAIN_STORE_SYSTEM_FOR_INDIVIDUAL: 'チェーン店の仕組みを個人店に',
    FAILURE_FREE_STORE_DEVELOPMENT: '失敗しない店舗開発',
    CHEAP_ALCOHOL_SUPPLY: 'お酒の仕入れ安く',
    DETAILED_PRODUCT_DESCRIPTION: '商品内容を細かく説明',
    ON_SITE_VISIT_ASSISTANCE: '内見同行します。',
    HYBRID_INDEPENDENT_AND_FC: '独立店とFCのハイブリッド',
    FUND_RAISING_SUPPORT: '資金調達支援',
    QUICKEST_STORE_OPENING: '最短での出店！',
    HIGH_PROFIT_STORE_KNOW_HOW: '高収益店舗ノウハウ！',
    LONG_TERM_STORE_MANAGEMENT_KNOW_HOW: '長く続く店舗運営ノウハウ',
    OTHER_SERVICES_COSTLY_CONSULTING_FOR_50K: '他サービスで何十万かかるコンサルが５万だけ',
    WANT_TO_ASK_ABOUT_OPENING: '開業のことを聞きたい',
    WANT_TO_CREATE_BUSINESS_PLAN: '事業計画作成したい',
  },
  customerAge: {
    TEENS: '10代',
    TWENTIES: '20代',
    THIRTIES: '30代',
    FORTIES: '40代',
    FIFTIES: '50代',
    SIXTIES: '60代',
    SEVENTIES: '70代',
    EIGHTIES: '80代',
    NINETIES: '90代',
  },
  customerGender: {
    MEN: '男性',
    WOMEN: '女性',
    OTHER: 'その他',
  },
  accessSource: {
    SEO_SIMULATION: 'SEO無料シミュレーション',
    SEO_CONTACT: 'SEOお問い合わせ',
    SOUGYOU: '創業手帳',
    TENBOSU: 'テンボス',
    SBI: 'SBI',
    FOOD_CONNECTION: 'フードコネクション',
    CHIJOU_SHOUKAI: '地上戦 紹介',
    CORPORATION: '法人',
    INDIVIDUAL: '個人',
    TENANTA: 'テナンタ',
    ZENKEN: 'zenken紹介',
    INOUE: '投資家井上様 紹介',
    OTHERS: 'その他',
  },
  accessSourceDetail: {
    ORGANIC: 'organic',
    GOOGLE_NAISOU: 'Google広告 内装',
    GOOGLE_KAIGYOU: 'Google広告 開業',
    YAHOO_NAISOU: 'Yahoo広告 内装',
    YAHOO_KAIGYOU: 'Yahoo広告 開業',
    DIRECT: 'direct',
    YAHOO: 'Yahoo広告',
    GOOGLE: 'Google広告',
    OTHERS: 'その他',
  },
};

export default TRANSLATIONS_JP;
