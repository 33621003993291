import { createSlice } from '@reduxjs/toolkit';
export interface AdminUserState {
  loading: boolean;
  list: unknown[];
  total: number;
  addAdminUserLoading: boolean;
  editAdminUserLoading: boolean;
  changeAdminUserStatusLoading: boolean;
  error: string | null;
  listAdminForSelect: [];
}

const initialState: AdminUserState = {
  addAdminUserLoading: false,
  loading: false,
  list: [],
  total: 0,
  editAdminUserLoading: false,
  changeAdminUserStatusLoading: false,
  error: null,
  listAdminForSelect: [],
};

const AdminUserProvider = createSlice({
  name: 'adminuser',
  initialState,
  reducers: {
    addAdminUserRequest(state, action) {
      state.addAdminUserLoading = true;
      state.error = null;
      return state;
    },
    addAdminUserSuccess(state) {
      state.addAdminUserLoading = false;
      state.error = null;
      return state;
    },
    addAdminUserFailure(state) {
      state.addAdminUserLoading = false;
      return state;
    },
    editAdminUserRequest(state, action) {
      state.editAdminUserLoading = true;
      state.error = null;
      return state;
    },
    editAdminUserSuccess(state) {
      state.editAdminUserLoading = false;
      state.error = null;
      return state;
    },
    editAdminUserFailure(state) {
      state.editAdminUserLoading = false;
      return state;
    },
    getAdminUserListRequest(state, action) {
      state.loading = true;
      return state;
    },
    getAdminUserListSuccess(state, action) {
      const { docs, totalDocs } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      return state;
    },
    getAdminUserListFailure(state) {
      state.loading = false;
      return state;
    },
    changeAdminUserStatusRequest(state, action) {
      state.changeAdminUserStatusLoading = true;
      return state;
    },
    changeAdminUserStatusSuccess(state) {
      state.changeAdminUserStatusLoading = false;
      return state;
    },
    changeAdminUserStatusFailure(state) {
      state.changeAdminUserStatusLoading = false;
      return state;
    },
    getAdminUserListForSelectRequest(state, action) {
      return state;
    },
    getAdminUserListForSelectSuccess(state, action) {
      const { docs } = action.payload;
      state.listAdminForSelect = docs;
      return state;
    },
  },
});

export const {
  getAdminUserListRequest,
  getAdminUserListSuccess,
  getAdminUserListFailure,
  addAdminUserRequest,
  addAdminUserSuccess,
  addAdminUserFailure,
  editAdminUserRequest,
  editAdminUserSuccess,
  editAdminUserFailure,
  changeAdminUserStatusRequest,
  changeAdminUserStatusSuccess,
  changeAdminUserStatusFailure,
  getAdminUserListForSelectRequest,
  getAdminUserListForSelectSuccess,
} = AdminUserProvider.actions;

export default AdminUserProvider.reducer;
