// Your custom code
const Helper = {
  trimmedValues: (values: { [key: string]: string }): { [key: string]: string } => {
    Object.keys(values).forEach((key) => (values[key] = values[key].trim()));
    return values;
  },
  formatCurrencyJPY: (value: number | bigint | undefined, suffix?: string): string => {
    if (value !== undefined) {
      const price = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return price + (suffix || '円');
    }
    return '';
  },
  convertYearMonth: (value: number | undefined): string => {
    if (value && value > 0) {
      const months = value % 12;
      const years = Math.floor(value / 12);
      return `${years}年${months}月`;
    } else if (value === 0) {
      return `0年0月`;
    }
    return '';
  },
  isSafari: (): boolean => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  isIphone: (): boolean => {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  },
};

export default Helper;
