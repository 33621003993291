/**
 * It is used to determine what field name for id index of record
 */
export const idCollection = '_id';

/**
 * It is used to determine the path response data
 */
export const responsePath = 'data';

/**
 * It is used to determine the path error data
 */
export const errorMessagePath = 'message';

/**
 * Custom plugin is plugin that you can customize by yourself
 *
 * Example: customPlugins=['example-custom-plugin']
 */
export const customPlugins = [];

/**
 * Installed plugin is plugin that you will install via npm
 *
 * Example: installedPlugins={
 *   'admin-console-plugin-booking': require('admin-console-plugin-booking'),
 * }
 */
export const installedPlugins = {};

/**
 * This is used for config api url endpoint of installed plugins
 *
 * You can review README.md of each plugins to see how to add api endpoint here
 *
 * Example:
 *  export const installedPluginApiUrl = {
 *    booking: {
 *      list: '/admin/bookings',
 *    },
 *  };
 */
export const installedPluginApiUrl = {};
