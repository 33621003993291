import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';

class AuthService extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  login = (params): Promise<AxiosResponse> => {
    return this.post('/accounts/operator/operators/login', params);
  };

  getCurrentUser = (): Promise<AxiosResponse> => {
    return this.get('/accounts/operator/operators/me', {});
  };

  resetPassword = (params): Promise<AxiosResponse> => {
    return this.post('/accounts/operator/operators/requestNewPassword', params);
  };

  setNewPassword = (params): Promise<AxiosResponse> => {
    return this.put('/accounts/operator/operators/newPassword', params);
  };
}

const service = new AuthService('');
export default service;
