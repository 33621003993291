import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';

class User extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  list = (companyId, params): Promise<AxiosResponse> => {
    return this.get(`/accounts/operator/companies/${companyId}/users`, { params });
  };

  add = (companyId, params): Promise<AxiosResponse> => {
    return this.post(`/accounts/operator/companies/${companyId}/users`, params);
  };

  edit = (companyId, id, params): Promise<AxiosResponse> => {
    return this.put(`/accounts/operator/companies/${companyId}/users/${id}`, params);
  };

  changeStatus = (companyId, id, params): Promise<AxiosResponse> => {
    return this.put(`/accounts/operator/companies/${companyId}/users/${id}/status`, params);
  };
}

const service = new User('');
export default service;
