import { createSlice } from '@reduxjs/toolkit';
import { TContactState } from './types';

const initialState: TContactState = {
  list: [],
  details: undefined,
  loading: false,
  total: 0,
};

const ContactProvider = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    getContactListRequest: (state, action) => {
      state.loading = true;
      return state;
    },
    getContactListSuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload.docs;
      return state;
    },
    getContactDetailRequest: (state, action) => {
      state.loading = true;
      return state;
    },
    getContactDetailSuccess: (state, action) => {
      state.loading = false;
      state.details = action.payload.data;
      return state;
    },
    deleteContactRequest: (state, action) => {
      state.loading = true;
      return state;
    },
    deleteContactSuccess: (state, action) => {
      state.loading = false;
      return state;
    },
  },
});

export const {
  getContactListRequest,
  getContactDetailRequest,
  getContactDetailSuccess,
  getContactListSuccess,
  deleteContactRequest,
  deleteContactSuccess,
} = ContactProvider.actions;
export default ContactProvider.reducer;
