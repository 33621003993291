/* eslint-disable max-len */

import translateEn from 'core/i18n/resources/en';

const TRANSLATIONS_EN = {
  ...translateEn,
  globally: {
    send: 'Send',
    successfully: 'Successfully',
    register: 'REGISTER',
    create: 'CREATE',
    update: 'UPDATE',
    deleteSuccessfully: 'Deleted successfully',
    delete: 'Delete',
    edit: 'Edit',
    createdAt: 'registration date',
    pleaseSelect: 'Please select',
    pleaseEnter: 'Please enter',
    save: 'Save',
    keep: 'Save',
    warningChangeRoute: 'The data has not been saved, do you want to leave the page?',
    saved: 'Saved',
    addFile: 'Add file',
    closeUp: 'Close',
    selectFiles: 'Select Files',
    doYouWantToDelete: 'Do you really want to delete this?',
    no: 'No',
    yes: 'Yes',
    add: 'Add',
    networkError: 'No network connection.',
    somethingWentWrong: 'Something went wrong.',
    editSuccessfully: 'Edit successfully',
    createSuccessfully: 'Create successfully',
  },
  login: {
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Click here if you forgot your password',
    login: 'Login',
  },
  resetPassword: {
    anEmailHasBeenSent:
      'An email has been sent to your email address (please check your spam folder as well).',
    confirmPassword: 'Password Confirm',
  },
  validation: {
    required: 'This item is required.',
    invalidFormat: 'This item has an invalid format.',
    least8Characters: '8 characters or more required',
    confirmPassword: 'Passwords are not match.',
    invalidNumber: 'Invalid Number',
    mostCharacters: 'This must be at most {{length}} characters',
    minLessMax: 'The minimum value should be less than the maximum value',
    invalidEmail: 'This email address is invalid',
    invalidUpload: '{{fileName}} is not a (PNG, JPG, HEIC) file',
    passwordRule: 'Please enter a password with 6 to 12 characters',
  },
  adminRoles: {
    admin: 'Admin',
    editor: 'Editor',
  },
  confirmModal: {
    deactivateAccount: 'Do you want to deactivate this account?',
    deleteItem: 'Do you want to delete this item?',
    doYouWantToRegister: 'Do you want to register with this content?',
    doYouWantToRegisterUpdate: 'Would you like to update with this content?',
    activateAccount: 'Do you want to activate this account?',
  },
  adminUser: {
    editWithoutPassword: '※If left blank, the password will not be updated',
    changedStatusSuccessfully: 'Changed status successfully',
    createNewAdmin: 'Register New Admin',
    editAdminAccount: 'Edit Admin Account',
  },
  menuBarCustom: {
    company: 'Direction Company',
    adminUser: 'Admin User Management',
    simulation: 'Investment amount simulation',
    message: 'Message',
    freeConsultation: 'Free Consultation',
    news: 'News management',
  },
  company: {
    createInfo: 'Direction company registration',
    name: 'company name',
    editInfo: 'Direction company editing',
    numberOfProject: 'number of projects',
    numberOfUser: 'number of accounts',
    accountManagement: 'Account Management',
  },
  columnTitleCustom: {
    role: 'Role',
    representative: 'Representative',
    tel: 'Phone number',
  },
  user: {
    representativeFlag: 'Representative flag',
    notRepresentative: 'not representative',
    name: 'name',
    accountRegistration: 'Direction company account registration',
    accountUpdating: 'Direction company account updating',
    directionCompanyAccount: 'DirectionCompanyAccount',
  },
  simulation: {
    status: 'status',
    createdAt: 'requested date',
    projectName: 'Project Title',
    name: "Requester's name",
    verification: 'Verification',
    header: 'Investment amount simulation request',
    headerDetail: 'Investment amount estimation simulation',
    requestInformation: 'Requester information',
    businessType: 'Business type',
    requesterName: "Requester's name",
    personInCharge: 'Misetsuku person in charge',
    editBasicInfo: 'Edit Basic Info',
    message: 'Message',
    otherSimulations: 'Examples of past investment amount simulations',
    approximateSimulation: 'Approximate Simulation',
    detailedSimulation: 'Detailed Simulation',
    unregistered: 'Unregistered',
    publishToRestaurant: 'Open to restaurant operators',
    recommendationLevel: 'Degree of recommendation for opening',
    monthlySale: 'Monthly Sale',
    monthlyProfit: 'Monthly Profit',
    storeInteriorCost: 'Store Interior Cost',
    overview: 'Overview',
    assumedStorePL: 'Assumed Store PL',
    earnings: 'Earnings',
    customerUnitPrice: 'Customer Unit Price',
    numberOfCustomers: 'Number Of Customers',
    numberOfTables: 'Number Of Tables',
    numOfSeats: 'Number Of Seats',
    occupancyRate: 'Occupancy Rate',
    tableTurnover: 'Number Of Revolutions',
    businessHours: 'Business Hours',
    businessDays: 'Business Days',
    table: 'table',
    seat: 'seat',
    times: 'times',
    time: 'time',
    day: 'day',
    runningCost: 'Running Cost',
    cost: 'Cost',
    personnelExpenses: 'Personnel Expenses',
    rent: 'rent',
    otherExpenses: 'Other Expenses',
    operatingIncome: 'Operating In come',
    assumedInitialCost: 'Assumed Initial Cost',
    propertyAcquisitionCost: 'property Acquisition Cost',
    deposit: 'deposit',
    franchiseFee: 'franchise Fee',
    openingFee: 'opening Fee',
    interiorEquipmentFee: 'interior Equipment Fee',
    kitchenEquipmentFee: 'kitchen Equipment Fee',
    paymentTerminalFee: 'payment Terminal Fee',
    paybackPeriod: 'Payback Period',
    publishedToRestaurant: 'Published to restaurant owner',
    unpublished: 'Unpublished',
    sendReportToRestaurantOwner: 'Send report to Restaurant owner',
    areYouSureToPublish:
      'Are you sure to publish this simulation to restaurant owner? Each form value will be copied to detail-ROI-simulation.',
    makeAProject: 'Make a project',
    areYouSurePublishDetail: 'Are you sure to publish this simulation to restaurant owner?',
    areYouSureUnpublishDetail: 'Are you sure to hide this simulation from restaurant owner?',
    haventSentSimpleROIFirst:
      'You can not input forms because simple-ROI-simulation has not been published for restaurant owner yet',
    customerName: 'Customer Name',
    customerCompany: 'Customer Company',
    categoryId: 'Business type',
    maximumFileAttach: 'A maximum of 10 files can be attached.',
    maximumSizeUpload: 'Please specify a file within {{size}}.',
    floorSpace: 'Floor Space',
    canNotDelete:
      "Can't delete this simulation because this data has been already converted into a project",
    showDeletedData: 'Also show deleted data',
    floorSpaceUnit: '坪',
    requestSimulation: 'Simulation Request Form',
    choosePrefecture: 'Select the prefecture',
    chooseCity: 'Select the city',
    selectBusinessType: 'Please select a business type',
    estimatedUnitPrice: 'Estimated customer unit price',
    telephone: 'Telephone Number',
    email: 'Email Address',
    registration: 'Registration',
    newUserCreated: 'New user information has been created.',
    password: 'Password',
    dontForget: "*Please write it down so you don't forget.",
    passwordNote:
      'If you have forgotten your password, please change your password from https://misetsuku-uat.scrum-dev.com/reset-password',
    priority: 'Priority',
    businessStatus: 'Business status',
    prefecture: 'Prefecture',
    timeRangeForCall: 'Time range for call',
    companyName: 'Company name',
    category: 'Category',
    latestCallDate: 'Latest call date',
    operatorName: 'Operator name',
    firstCallDate: 'First call date',
    callConnectedDate: 'Call connected date',
    interviewDate: 'Interview date',
    requesterNameKana: "Requester's name（Kana）",
    customerCategory: 'Customer category',
    openingDate: 'Opening date',
    requiredCapital: 'Required capital',
    propertyContractFlag: 'Property contract flag',
    designerConstructionContract: 'Designer construction contract',
    businessPlanFlag: 'Business plan flag',
    preparingFunds: 'Preparing funds',
    experienceFlag: 'Experience flag',
    inquiryNeeds: 'Inquiry needs',
    appealPoint1: 'Appeal point 1',
    appealPoint2: 'Appeal point 2',
    age: 'Age',
    gender: 'Gender',
    contractDate: 'Contract date',
    meetingNeeds: 'Meeting needs',
    orderDecidingFactor: 'Order deciding factor',
    accessSource: 'Access source',
    accessSourceDetail: 'Access source detail',
    openingDateCheckbox: 'It is not yet decided',
    quickEdit: 'Quick edit',
    year: 'Year',
    month: 'Month',
    send: 'Send',
    sendInformation: 'Send information',
    sendInfoConfirmMessage: `We will send {{name}} an email with the login information for your personal page.
      Your previous password will be reset.
      If there are no problems, please click send.`,
    sendInformationSuccess: 'Login information has been sent.',
    memo: 'To do',
    memoList: 'Memo list',
    updateSuccess: 'Update successfully!',
    exceedLimitation: 'The inputted content must not be longer than {n} characters.',
    addMemo: 'Add memo',
  },
  simulationStatus: {
    UNDER_SIMPLE_ROI: 'UNDER_SIMPLE_ROI',
    COMPLETE_SIMPLE_ROI: 'COMPLETE_SIMPLE_ROI',
    UNDER_DETAIL_ROI: 'UNDER_DETAIL_ROI',
    COMPLETE_DETAIL_ROI: 'COMPLETE_DETAIL_ROI',
  },
  project: {
    newProjectRegistration: 'New Project Registration',
    investmentAmount: 'Investment amount simulation information',
    linkToInvestmentAmount: 'Link to investment amount simulation',
    companyInfo: 'Direction company information',
    haventPickPresentative:
      "This direction company don't have president direction user. Please register president of the company",
    designFee: 'Design fee',
    projectName: 'Project name',
    menu: 'Project',
    toProjectDetail: 'To project details page',
    projectInfo: 'Project Info',
    directionUser: 'Direction User',
    restaurantOwner: 'Restaurant Owner',
    directionCompanyName: 'Direction Company Name',
    kitchenEquipmentFee: 'kitchen Equipment Fee',
    budgetEditing: 'Direction company, various budget editing',
    storeDevelopmentQuotationContract: 'Store Development Quotation/Contract',
    storeDevelopment: 'Store Development',
    remarks: 'Remarks',
    subtextComment: 'Information that is publicly available to users',
    enterYourComment: 'Please enter your comment',
    restaurantAndDirection: 'Restaurant/Direction',
  },
  projectStatus: {
    STORE_DEVELOPMENT_CONTRACT: 'STORE_DEVELOPMENT_CONTRACT',
    STORE_DEVELOPMENT: 'STORE_DEVELOPMENT',
    DESIGN_CONTRACT: 'DESIGN_CONTRACT',
    DESIGN: 'DESIGN',
    CONSTRUCTION_CONTRACT: 'CONSTRUCTION_CONTRACT',
    CONSTRUCTION: 'CONSTRUCTION',
    COMPLETE_CONTRACT: 'COMPLETE_CONTRACT',
    COMPLETE: 'COMPLETE',
  },
  projectFileType: {
    SCHEDULE_WHOLE: 'SCHEDULE_WHOLE',
    QUOTATION_STORE_DEVELOPMENT: 'QUOTATION_STORE_DEVELOPMENT',
    CONTRACT_STORE_DEVELOPMENT: 'CONTRACT_STORE_DEVELOPMENT',
    QUESTIONNAIRE_STORE_DEVELOPMENT: 'QUESTIONNAIRE_STORE_DEVELOPMENT',
    SKELTON_STORE_DEVELOPMENT: 'SKELTON_STORE_DEVELOPMENT',
    MASTERPLAN_STORE_DEVELOPMENT: 'MASTERPLAN_STORE_DEVELOPMENT',
    QUOTATION_DESIGN_CONTRACT: 'QUOTATION_DESIGN_CONTRACT',
    CONTRACT_DESIGN_CONTRACT: 'CONTRACT_DESIGN_CONTRACT',
    INVOICE_DESIGN_CONTRACT: 'INVOICE_DESIGN_CONTRACT',
    SCHEDULE_DESIGN: 'SCHEDULE_DESIGN',
    DESIGN_DESIGN: 'DESIGN_DESIGN',
    BASIC_DIAGRAM_DESIGN: 'BASIC_DIAGRAM_DESIGN',
    QUOTATION_CONSTRUCTION_CONTRACT: 'QUOTATION_CONSTRUCTION_CONTRACT',
    CONTRACT_CONSTRUCTION_CONTRACT: 'CONTRACT_CONSTRUCTION_CONTRACT',
    INVOICE_CONSTRUCTION_CONTRACT: 'INVOICE_CONSTRUCTION_CONTRACT',
    SCHEDULE_CONSTRUCTION: 'SCHEDULE_CONSTRUCTION',
    DOCS_COMPLETE: 'DOCS_COMPLETE',
  },
  message: {
    roomParticipant: 'Room Participant',
    send: 'Send',
    attached: 'attached',
    title: 'Message',
    notifyByEmail: 'Notify By Email',
    you: 'you',
    delete: 'Delete',
  },
  timeStamp: {
    justNow: 'Just now',
    minutesAgo: ' minutes ago',
    hoursAgo: ' hours ago',
    daysAgo: ' days ago',
    weeksAgo: 'weeks ago',
    monthsAgo: 'months ago',
    year: '{year}',
  },
  roomType: {
    AR: 'AR',
    AD: 'AD',
    ARD: 'ARD',
  },
  consultation: {
    name: "Customer' name",
    email: 'Email address',
    consultationDetails: 'Consultation details',
    goToDetail: 'Detail',
    delete: 'Delete',
    header: 'Free consultations list',
    detailHeader: 'Free consultation details',
    status: 'Status',
    createdAt: 'Created date',
  },
  news: {
    delete: 'Delete',
    createNewsHeader: 'Create news',
    title: 'Title',
    content: 'Content',
    publishTime: 'Publish datetime',
  },
  customerCategory: {
    CORPORATION: 'CORPORATION',
    INDIVIDUAL: 'INDIVIDUAL',
  },
  designerConstructionContract: {
    CONSTRUCTION_FIXED: 'CONSTRUCTION_FIXED',
    UNDER_ESTIMATION: 'UNDER_ESTIMATION',
    DESIGNER_FIXED: 'DESIGNER_FIXED',
    NONE: 'NONE',
  },
  priority: {
    S: 'S',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
  },
  businessStatus: {
    CALL_NOT_CONNECTED: 'CALL_NOT_CONNECTED',
    CALL_NO_NEEDS: 'CALL_NO_NEEDS',
    AFTER_CALL_NOT_CONNECTED: 'AFTER_CALL_NOT_CONNECTED',
    NEW_MEETING_SCHEDULED: 'NEW_MEETING_SCHEDULED',
    NEW_MEETING_DECIDED: 'NEW_MEETING_DECIDED',
    AFTER_MEETING_NOT_CONNECTED: 'AFTER_MEETING_NOT_CONNECTED',
    NEW_MEETING_DONE: 'NEW_MEETING_DONE',
    CONTRACT_NEGOTIATION: 'CONTRACT_NEGOTIATION',
    REVIVAL_NEGOTIATION_WAITING: 'REVIVAL_NEGOTIATION_WAITING',
    REVIVAL_MEETING_DONE: 'REVIVAL_MEETING_DONE',
    STORE_DEVELOPMENT_CONTRACT: 'STORE_DEVELOPMENT_CONTRACT',
    LICENSE_CONTRACT: 'LICENSE_CONTRACT',
  },
  inquiryNeed: {
    FIND_INTERIOR_COMPANY: 'FIND_INTERIOR_COMPANY',
    PROPERTY_SELECTION_ASSISTANCE: 'PROPERTY_SELECTION_ASSISTANCE',
    PROPERTY_INTRODUCTION: 'PROPERTY_INTRODUCTION',
    RESTAURANT_STARTUP_GENERAL: 'RESTAURANT_STARTUP_GENERAL',
    FUND_RAISING: 'FUND_RAISING',
  },
  appealPoint: {
    FULL_SUPPORT_BEFORE_OPENING: 'FULL_SUPPORT_BEFORE_OPENING',
    MAKINO_RECORDS: 'MAKINO_RECORDS',
    CHAIN_STORE_SYSTEM_FOR_INDIVIDUAL: 'CHAIN_STORE_SYSTEM_FOR_INDIVIDUAL',
    FAILURE_FREE_STORE_DEVELOPMENT: 'FAILURE_FREE_STORE_DEVELOPMENT',
    CHEAP_ALCOHOL_SUPPLY: 'CHEAP_ALCOHOL_SUPPLY',
    DETAILED_PRODUCT_DESCRIPTION: 'DETAILED_PRODUCT_DESCRIPTION',
    ON_SITE_VISIT_ASSISTANCE: 'ON_SITE_VISIT_ASSISTANCE',
    HYBRID_INDEPENDENT_AND_FC: 'HYBRID_INDEPENDENT_AND_FC',
    FUND_RAISING_SUPPORT: 'FUND_RAISING_SUPPORT',
    QUICKEST_STORE_OPENING: 'QUICKEST_STORE_OPENING',
    HIGH_PROFIT_STORE_KNOW_HOW: 'HIGH_PROFIT_STORE_KNOW_HOW',
    LONG_TERM_STORE_MANAGEMENT_KNOW_HOW: 'LONG_TERM_STORE_MANAGEMENT_KNOW_HOW',
    OTHER_SERVICES_COSTLY_CONSULTING_FOR_50K: 'OTHER_SERVICES_COSTLY_CONSULTING_FOR_50K',
    WANT_TO_ASK_ABOUT_OPENING: 'WANT_TO_ASK_ABOUT_OPENING',
    WANT_TO_CREATE_BUSINESS_PLAN: 'WANT_TO_CREATE_BUSINESS_PLAN',
  },
  customerAge: {
    TEENS: 'TEENS',
    TWENTIES: 'TWENTIES',
    THIRTIES: 'THIRTIES',
    FORTIES: 'FORTIES',
    FIFTIES: 'FIFTIES',
    SIXTIES: 'SIXTIES',
    SEVENTIES: 'SEVENTIES',
    EIGHTIES: 'EIGHTIES',
    NINETIES: 'NINETIES',
  },
  customerGender: {
    MEN: 'MEN',
    WOMEN: 'WOMEN',
    OTHER: 'OTHER',
  },
  accessSource: {
    SEO_SIMULATION: 'SEO_SIMULATION',
    SEO_CONTACT: 'SEO_CONTACT',
    SOUGYOU: 'SOUGYOU',
    TENBOSU: 'TENBOSU',
    SBI: 'SBI',
    FOOD_CONNECTION: 'FOOD_CONNECTION',
    CHIJOU_SHOUKAI: 'CHIJOU_SHOUKAI',
    CORPORATION: 'CORPORATION',
    INDIVIDUAL: 'INDIVIDUAL',
    TENANTA: 'TENANTA',
    ZENKEN: 'ZENKEN',
    INOUE: 'INOUE',
    OTHERS: 'OTHERS',
  },
  accessSourceDetail: {
    ORGANIC: 'ORGANIC',
    GOOGLE_NAISOU: 'GOOGLE_NAISOU',
    GOOGLE_KAIGYOU: 'GOOGLE_KAIGYOU',
    YAHOO_NAISOU: 'YAHOO_NAISOU',
    YAHOO_KAIGYOU: 'YAHOO_KAIGYOU',
    DIRECT: 'DIRECT',
    YAHOO: 'YAHOO',
    GOOGLE: 'GOOGLE',
    OTHERS: 'OTHERS',
  },
};

export default TRANSLATIONS_EN;
