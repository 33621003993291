import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { ObjectType } from 'core/utils/types';
import { UserStatus } from 'utils/constants';
export interface CompanyState {
  loading: boolean;
  list: unknown[];
  total: number;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  error: string | null;
  companyListForSelect: [];
}

const initialState: CompanyState = {
  addLoading: false,
  loading: false,
  list: [],
  total: 0,
  editLoading: false,
  deleteLoading: false,
  error: null,
  companyListForSelect: [],
};

const CompanyProvider = createSlice({
  name: 'company',
  initialState,
  reducers: {
    addCompanyRequest(state, action) {
      state.addLoading = true;
      state.error = null;
      return state;
    },
    addCompanySuccess(state) {
      state.addLoading = false;
      state.error = null;
      return state;
    },
    addCompanyFailure(state, action) {
      const { payload } = action;
      state.addLoading = false;
      state.error = payload.error.message;
      return state;
    },
    editCompanyRequest(state, action) {
      state.editLoading = true;
      state.error = null;
      return state;
    },
    editCompanySuccess(state) {
      state.editLoading = false;
      state.error = null;
      return state;
    },
    editCompanyFailure(state, action) {
      const { payload } = action;
      state.editLoading = false;
      state.error = payload.error.message;
      return state;
    },
    getCompanyListRequest(state, action) {
      state.loading = true;
      return state;
    },
    getCompanyListSuccess(state, action) {
      const { docs, totalDocs } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      return state;
    },
    getCompanyListFailure(state) {
      state.loading = false;
      return state;
    },
    deleteCompanyRequest(state, action) {
      state.deleteLoading = true;
      return state;
    },
    deleteCompanySuccess(state) {
      state.deleteLoading = false;
      return state;
    },
    deleteCompanyFailure(state) {
      state.deleteLoading = false;
      return state;
    },
    getCompanyListForSelectRequest(state, action) {
      return state;
    },
    getCompanyListForSelectSuccess(state, action) {
      const { docs } = action.payload;
      state.companyListForSelect = docs;
      return state;
    },
  },
});

export const {
  getCompanyListRequest,
  getCompanyListSuccess,
  getCompanyListFailure,
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  editCompanyRequest,
  editCompanySuccess,
  editCompanyFailure,
  deleteCompanyRequest,
  deleteCompanySuccess,
  deleteCompanyFailure,
  getCompanyListForSelectRequest,
  getCompanyListForSelectSuccess,
} = CompanyProvider.actions;

export default CompanyProvider.reducer;
