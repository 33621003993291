import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ObjectType } from 'core/utils/types';
import { TSelectableData } from 'utils/types';
import { MemoData } from 'containers/SimulationRequest/AddMemoModal/MemoItem';
import { WithId, WithSearchQuery } from './types';
export interface SimulationState {
  loading: boolean;
  list: unknown[];
  total: number;
  detail: ObjectType;
  otherList: unknown[];
  isUpdatingApproximateSimulation: boolean;
  isUpdatingDetailSimulation: boolean;
  isPublishDetailSimulation: boolean;
  isUpdatingSimulation: boolean;
  isQuickEditingSimulation: boolean;
  isUpdateMemos: boolean;
  categoriesList: [];
  updateError: string;
  prefectures: TSelectableData[];
  cities: TSelectableData[];
  customer?: {
    email: string;
    password?: string;
    isExisted: boolean;
  };
}

const initialState: SimulationState = {
  loading: false,
  list: [],
  total: 0,
  detail: {},
  otherList: [],
  isUpdatingApproximateSimulation: false,
  isUpdatingDetailSimulation: false,
  isPublishDetailSimulation: false,
  categoriesList: [],
  isUpdatingSimulation: false,
  isQuickEditingSimulation: false,
  isUpdateMemos: false,
  updateError: '',
  prefectures: [],
  cities: [],
  customer: undefined,
};

const SimulationProvider = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    getSimulationListRequest(state, action) {
      state.loading = true;
      return state;
    },
    getSimulationListSuccess(state, action) {
      const { docs, totalDocs } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      return state;
    },
    getSimulationListFailure(state) {
      state.loading = false;
      return state;
    },

    getSimulationDetailRequest(state, action) {
      state.loading = true;
      return state;
    },
    getSimulationDetailSuccess(state, action) {
      state.detail = action.payload;
      state.loading = false;
      return state;
    },
    getSimulationDetailFailure(state) {
      state.loading = false;
      return state;
    },
    resetSimulationDetail(state) {
      state.detail = {};
      return state;
    },
    getOtherSimulationListRequest(state, action) {
      return state;
    },
    getOtherSimulationListSuccess(state, action) {
      state.otherList = action.payload.docs;
      return state;
    },
    getOtherSimulationListFailure(state) {
      return state;
    },
    downLoadFileRequest(state, action) {
      return state;
    },
    updateApproximateSimulationRequest(state, action) {
      state.isUpdatingApproximateSimulation = true;
      return state;
    },
    updateApproximateSimulationSuccess(state) {
      state.isUpdatingApproximateSimulation = false;
      return state;
    },
    updateApproximateSimulationFailure(state) {
      state.isUpdatingApproximateSimulation = false;
      return state;
    },
    updateDetailSimulationRequest(state, action) {
      state.isUpdatingDetailSimulation = true;
      return state;
    },
    updateDetailSimulationSuccess(state) {
      state.isUpdatingDetailSimulation = false;
      return state;
    },
    updateDetailSimulationFailure(state) {
      state.isUpdatingDetailSimulation = false;
      return state;
    },
    publishDetailSimulationRequest(state, action) {
      state.isPublishDetailSimulation = true;
      return state;
    },
    publishDetailSimulationSuccess(state) {
      state.isPublishDetailSimulation = false;
      return state;
    },
    publishDetailSimulationFailure(state) {
      state.isPublishDetailSimulation = false;
      return state;
    },
    getCategoriesListRequest(state) {
      return state;
    },
    getCategoriesListSuccess(state, action) {
      state.categoriesList = action.payload.result;
      return state;
    },
    updateSimulationRequest(state, action) {
      state.isUpdatingSimulation = true;
      state.updateError = '';
      return state;
    },
    updateSimulationSuccess(state) {
      state.isUpdatingSimulation = false;
      state.updateError = '';
      return state;
    },
    updateSimulationFailure(state, action) {
      state.isUpdatingSimulation = false;
      state.updateError = action.payload;
      return state;
    },
    quickEditSimulationRequest(state, action) {
      state.isQuickEditingSimulation = true;
      state.updateError = '';
      return state;
    },
    quickEditSimulationSuccess(state) {
      state.isQuickEditingSimulation = false;
      state.updateError = '';
      return state;
    },
    quickEditSimulationFailure(state, action) {
      state.isQuickEditingSimulation = false;
      state.updateError = action.payload;
      return state;
    },
    deleteSimulationRequest(state, action) {
      return state;
    },
    getPrefectureListRequest(state) {
      return state;
    },
    getPrefectureListSuccess(state, action) {
      state.prefectures = action.payload.docs;
      localStorage.setItem('prefectures', JSON.stringify(action.payload.docs));
      return state;
    },
    getPrefectureListFailure(state) {
      const cachedPrefectures = localStorage.getItem('prefectures');
      state.prefectures = cachedPrefectures ? JSON.parse(cachedPrefectures) : [];
      return state;
    },
    getCityListRequest(state, action) {
      return state;
    },
    getCityListSuccess(state, action) {
      state.cities = action.payload.docs;
      return state;
    },
    requestSimulationRequest(state, action) {
      return state;
    },
    requestSimulationRequestSuccess(state, action) {
      if (action.payload && !!action.payload.password) {
        state.customer = {
          email: action.payload.email,
          password: action.payload.password,
          isExisted: false,
        };
      } else {
        state.customer = {
          email: action.payload.email,
          isExisted: true,
        };
      }
      return state;
    },
    clearSimulationRequestData(state) {
      state.customer = undefined;
      return state;
    },
    sendInformationRequest(state, action) {
      return state;
    },
    updateMemosRequest(
      state,
      action: PayloadAction<WithId<WithSearchQuery<{ memos: MemoData[] }>>>,
    ) {
      return {
        ...state,
        isUpdateMemos: true,
      };
    },
    updateMemosSuccess(state) {
      return {
        ...state,
        isUpdateMemos: false,
      };
    },
    updateMemosFailure(state) {
      return {
        ...state,
        isUpdateMemos: false,
      };
    },
  },
});

export const {
  getSimulationListRequest,
  getSimulationListSuccess,
  getSimulationListFailure,
  getSimulationDetailRequest,
  getSimulationDetailSuccess,
  getSimulationDetailFailure,
  resetSimulationDetail,
  getOtherSimulationListRequest,
  getOtherSimulationListSuccess,
  downLoadFileRequest,
  updateApproximateSimulationRequest,
  updateApproximateSimulationSuccess,
  updateApproximateSimulationFailure,
  updateDetailSimulationRequest,
  updateDetailSimulationSuccess,
  updateDetailSimulationFailure,
  publishDetailSimulationRequest,
  publishDetailSimulationSuccess,
  publishDetailSimulationFailure,
  getCategoriesListRequest,
  getCategoriesListSuccess,
  updateSimulationRequest,
  updateSimulationSuccess,
  updateSimulationFailure,
  quickEditSimulationRequest,
  quickEditSimulationSuccess,
  quickEditSimulationFailure,
  deleteSimulationRequest,
  getPrefectureListRequest,
  getPrefectureListSuccess,
  getPrefectureListFailure,
  getCityListRequest,
  getCityListSuccess,
  requestSimulationRequest,
  requestSimulationRequestSuccess,
  clearSimulationRequestData,
  sendInformationRequest,
  updateMemosRequest,
  updateMemosSuccess,
  updateMemosFailure,
} = SimulationProvider.actions;

export default SimulationProvider.reducer;
