import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  currentUser: {
    _id: string;
    firebaseUserId: string;
  };
  isLoadingcurrentUser: boolean;
  isLoadingResetPassword: boolean;
  isLoadingSetNewPassword: boolean;
  realTimeCurrentUser: {
    avatar?: string;
    name?: string;
    numberOfUnreadMessage: number;
    originalId: string;
    type: string;
  };
  isListenFirebaseAuth: boolean;
}

const initialState = {
  loading: false,
  currentUser: {},
  isLoadingcurrentUser: false,
  isLoadingSetNewPassword: false,
  realTimeCurrentUser: {},
  isListenFirebaseAuth: false,
} as AuthState;

// For the error action, you can declare a general action to stop all loading
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest(state, action) {
      state.loading = true;
      return state;
    },
    loginSuccess(state) {
      state.loading = false;
      return state;
    },
    loginError(state) {
      state.loading = false;
      return state;
    },
    resetPasswordRequest(state, action) {
      state.isLoadingResetPassword = true;
      return state;
    },
    resetPasswordSuccess(state) {
      state.isLoadingResetPassword = false;
      return state;
    },
    resetPasswordError(state) {
      state.isLoadingResetPassword = false;
      return state;
    },
    getCurrentUserRequest(state) {
      state.isLoadingcurrentUser = false;
      return state;
    },
    getCurrentUserSuccess(state, action) {
      state.isLoadingcurrentUser = true;
      state.currentUser = action.payload;
      return state;
    },
    getCurrentUserError(state) {
      state.isLoadingcurrentUser = false;
      return state;
    },
    logoutRequest(state) {
      return state;
    },
    setNewPasswordRequest(state, action) {
      state.isLoadingSetNewPassword = true;
      return state;
    },
    setNewPasswordSuccess(state) {
      state.isLoadingSetNewPassword = false;
      return state;
    },
    setNewPasswordError(state) {
      state.isLoadingSetNewPassword = false;
      return state;
    },
    listenUserDetailRequest(state, action) {
      state.isListenFirebaseAuth = true;
      return state;
    },
    listenUserDetailSuccess(state, action) {
      state.realTimeCurrentUser = action.payload;
      return state;
    },
    listenUserDetailRemove(state) {
      state.isListenFirebaseAuth = false;
      return state;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginError,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
  logoutRequest,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  setNewPasswordRequest,
  setNewPasswordSuccess,
  setNewPasswordError,
  listenUserDetailRequest,
  listenUserDetailSuccess,
  listenUserDetailRemove,
} = authSlice.actions;

export default authSlice.reducer;
