import { getAuth, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
  doc,
  increment,
  Timestamp,
  writeBatch,
  getDocs,
  runTransaction,
  endBefore,
  QueryConstraint,
  getDoc,
} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { buffers, eventChannel, EventChannel } from 'redux-saga';
import _omit from 'lodash/omit';
import Helper from 'core/utils/helpers';
import MessageService from 'providers/MessageProvider/service';
import {
  CONVERSATION_LIMIT,
  FIREBASE_DOC_STATUS,
  MESSAGE_LIMIT,
  MESSAGE_TYPE,
} from 'utils/constants';
import {
  IConversationItem,
  ILastMessageType,
  IMessageType,
  IRoomType,
} from 'providers/MessageProvider/interfaces';
import { IMessageId } from 'providers/MessageProvider/slice';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);

export const handleLoginFirebase = (): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        try {
          let uid = '';
          const token = Helper.getAuthToken();
          if (token) {
            if (!user) {
              const result = await MessageService.getFirebaseToken();
              const signInUser = await signInWithCustomToken(auth, result.data.token);
              uid = signInUser.user.uid;
            } else {
              uid = user.uid;
            }
            return resolve(uid);
          } else {
            resolve('');
          }
        } catch (e) {
          resolve('');
        }
      });
    } catch (err) {
      return reject(err);
    }
  });

export const handleLogoutFirebase = (): Promise<string> => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        return resolve('');
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const handleListenConversationList = ({
  userId,
  before,
}: {
  userId: string;
  before?: Timestamp | null;
}): EventChannel<unknown> => {
  const queryConstraints: QueryConstraint[] = [
    where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
    where('currentMembers', 'array-contains', userId),
    orderBy('lastMessage.updatedAt', 'desc'),
  ];
  if (before) {
    queryConstraints.push(endBefore(before));
  }
  queryConstraints.push(limit(CONVERSATION_LIMIT));
  const conversationsQuery = query(
    collection(db, 'rooms'),

    ...queryConstraints,
  );

  return eventChannel((emit) => {
    const unsubcribe = onSnapshot(
      conversationsQuery,
      (querySnapshot: QuerySnapshot<IConversationItem>) => {
        const conversationIds: string[] = [];
        const conversations = {};
        const noDataUsers = [];
        const messageType = {};
        querySnapshot.docChanges().forEach((change) => {
          const documentId = change.doc.id;
          if (change.type !== 'removed') {
            const data = change.doc.data();
            const roomUpdatedAt = data.updatedAt;
            const lastMessageUpdatedAt = data.lastMessage?.updatedAt;
            const isNewMessage = lastMessageUpdatedAt
              ? roomUpdatedAt.isEqual(lastMessageUpdatedAt)
              : true;
            messageType[documentId] = isNewMessage ? 'new_message' : 'update_info';
            conversations[documentId] = change.doc.data();
            conversationIds.push(documentId);
          }
        });
        emit({
          conversationIds,
          conversations,
          noDataUsers,
          messageType,
        });
      },
    );
    return () => {
      unsubcribe();
    };
  });
};

export const handleListenConversationUpdated = ({
  userId,
}: {
  userId: string;
}): EventChannel<{ conversations: Partial<Record<string, IConversationItem>> }> => {
  const conversationsQuery = query(
    collection(db, 'rooms'),
    where('currentMembers', 'array-contains', userId),
    orderBy('updatedAt', 'desc'),
    endBefore(new Date()),
    limit(1),
  );

  return eventChannel((emit) => {
    const unsubcribe = onSnapshot(
      conversationsQuery,
      (querySnapshot: QuerySnapshot<IConversationItem>) => {
        const conversations = {};
        querySnapshot.docChanges().forEach((change) => {
          const documentId = change.doc.id;
          if (change.type !== 'removed') {
            conversations[documentId] = change.doc.data();
          }
        });
        emit({
          conversations,
        });
      },
    );
    return () => {
      unsubcribe();
    };
  });
};

export const handleListenUserDetail = (action: { userId: string }): EventChannel<unknown> => {
  const { userId } = action;
  return eventChannel((emit) => {
    const userDetailRef = doc(db, 'users', userId);
    const unsubcribe = onSnapshot(userDetailRef, (snap) => {
      emit(snap.data());
    });
    return () => {
      unsubcribe();
    };
  }, buffers.sliding(1));
};

export const handleListenRoomDetail = (roomId: string): EventChannel<IRoomType> => {
  return eventChannel((emit) => {
    const unsubscribe = onSnapshot(doc(db, 'rooms', roomId), (doc) => {
      emit({ ...(doc.data() as IRoomType), _id: doc.id });
    });
    return unsubscribe;
  }, buffers.sliding(1));
};

export const handleSendMessage = (data: {
  roomId: string;
  senderId: string;
  type: 'TEXT' | 'IMAGE' | 'VIDEO' | 'FILE';
  value: string;
  extraInfo?: {
    originName?: string;
    key?: string;
    url?: string;
    isPublic?: boolean;
  };
  readingUsers: Record<string, boolean>;
  currentMembers: string[];
}): Promise<string> => {
  return new Promise((resolve, reject) => {
    const batch = writeBatch(db);
    const roomCollection = doc(collection(db, 'rooms'), data.roomId);
    const timeStamp = Timestamp.now();
    // add message
    batch.set(doc(collection(roomCollection, 'messages')), {
      status: 'ACTIVE',
      ..._omit(data, ['roomId', 'currentMembers']),
      updatedAt: timeStamp,
      createdAt: timeStamp,
    });
    // update last message and unread user for this room
    const numberOfUnreadMessageUser = {};
    data.currentMembers.forEach((userId) => {
      if (userId !== data.senderId) {
        numberOfUnreadMessageUser[`numberOfUnreadMessage.${userId}`] = increment(1);
      }
    });
    batch.update(roomCollection, {
      lastMessage: {
        ..._omit(data, ['readingUsers', 'roomId', 'currentMembers']),
        updatedAt: timeStamp,
        createdAt: timeStamp,
      },
      ...numberOfUnreadMessageUser,
    });
    // update unread number for all users
    for (const userId in data.readingUsers) {
      if (!data.readingUsers[userId]) {
        batch.update(doc(collection(db, 'users'), userId), {
          numberOfUnreadMessage: increment(1),
        });
      }
    }
    batch
      .commit()
      .then(() => {
        resolve('');
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleListenMessage = (
  roomId: string,
): EventChannel<{
  messageIds: IMessageId[];
  messageData: Partial<Record<string, IMessageType>>;
}> => {
  return eventChannel((emit) => {
    const messageQuery = query(
      collection(doc(collection(db, 'rooms'), roomId), 'messages'),
      where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
      orderBy('createdAt', 'desc'),
      limit(MESSAGE_LIMIT),
    );
    const unsubscribe = onSnapshot(messageQuery, (docs) => {
      const messageIds: IMessageId[] = [];
      const messageData = {};
      docs.docChanges().forEach((doc) => {
        const data = doc.doc.data();
        if (doc.type === 'added') {
          if (!messageData[doc.doc.id]) {
            messageIds.push({
              id: doc.doc.id,
              createdAt: data.createdAt.toDate(),
            });
            messageData[doc.doc.id] = data;
          }
        } else if (doc.type === 'modified') {
          messageData[doc.doc.id] = data;
        }
      });
      if (docs.docChanges().length !== 0) {
        emit({ messageIds, messageData });
      }
    });
    return unsubscribe;
  }, buffers.sliding(1));
};

export const handleReadRoom = (data: { roomId: string; senderId: string }): Promise<string> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        await runTransaction(db, async (transaction) => {
          const roomCollection = doc(collection(db, 'rooms'), data.roomId);
          const userCollection = doc(collection(db, 'users'), data.senderId);
          const roomDataSnap = await transaction.get(roomCollection);
          const userDataSnap = await transaction.get(userCollection);
          if (!roomDataSnap.exists() || !userDataSnap.exists()) {
            throw new Error('User/Room is not exist');
          }
          const roomData = roomDataSnap.data();
          const numberOfUnread = roomData.numberOfUnreadMessage[data.senderId];
          // Update numberOfUnread in room
          const numberOfUnreadMessageField = `numberOfUnreadMessage.${data.senderId}`;
          transaction.update(roomCollection, {
            [numberOfUnreadMessageField]: increment(-numberOfUnread),
          });

          // Update numberOfUnread of user
          const userData = userDataSnap.data();
          const userNumberOfUnread = userData.numberOfUnreadMessage - numberOfUnread;
          transaction.update(userCollection, {
            numberOfUnreadMessage: userNumberOfUnread,
          });

          // Update all messages as read
          const readingUsersField = `readingUsers.${data.senderId}`;
          const messages = await getDocs(
            query(
              collection(roomCollection, 'messages'),
              where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
              where(readingUsersField, '==', false),
            ),
          );
          messages.forEach((message) => {
            transaction.update(doc(collection(roomCollection, 'messages'), message.id), {
              [readingUsersField]: true,
            });
          });

          return roomDataSnap.data();
        });
        resolve('');
      } catch (err) {
        reject(err);
      }
    })();
  });
};

type ILastMessageWithIdType = ILastMessageType & { id: string };

export const handleDeleteMessage = (data: {
  roomId: string;
  messageId: string;
}): Promise<string> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        await runTransaction(db, async (transaction) => {
          const timeStamp = Timestamp.now();
          const roomCollection = doc(collection(db, 'rooms'), data.roomId);
          const roomDocSnap = await getDoc(roomCollection);
          if (!roomDocSnap.exists()) {
            throw new Error('Room is not exist');
          }
          const roomData = roomDocSnap.data();
          const messageCollection = doc(
            collection(doc(collection(db, 'rooms'), data.roomId), 'messages'),
            data.messageId,
          );
          const queryMessageList = query(
            collection(doc(collection(db, 'rooms'), data.roomId), 'messages'),
            where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
            orderBy('createdAt', 'desc'),
            limit(2),
          );

          const messagelistSnap = await getDocs(queryMessageList);
          const messages: ILastMessageWithIdType[] = [];
          messagelistSnap.forEach((message) => {
            const messageData = message.data();
            messages.push({
              type: messageData.type,
              value: messageData.value,
              createdAt: messageData.createdAt,
              updatedAt: messageData.updatedAt,
              extraInfo: messageData.extraInfo,
              senderId: messageData.senderId,
              id: message.id,
            });
          });
          const messageDataSnap = await transaction.get(messageCollection);

          if (!messageDataSnap.exists()) {
            throw new Error('Message is not exist');
          }
          const messageData = messageDataSnap.data();
          // Decrease unread number for all user in users collection
          const readingUsersRead = {};
          const numberOfUnreadMessageFields = {};
          for (const userId in messageData.readingUsers) {
            if (!messageData.readingUsers[userId]) {
              readingUsersRead[`readingUsers.${userId}`] = true;
              numberOfUnreadMessageFields[`numberOfUnreadMessage.${userId}`] = increment(-1);
              transaction.update(doc(collection(db, 'users'), userId), {
                numberOfUnreadMessage: increment(-1),
              });
            }
          }
          // Update delete status and set read for all members in messages collection
          transaction.update(messageCollection, {
            status: FIREBASE_DOC_STATUS.DELETED,
            ...readingUsersRead,
          });
          // Update numberOfUnread, last deleted message id and lastmessage in room
          const paramsRoom: {
            lastMessage?: Partial<ILastMessageType>;
          } = {};
          if (messages.length === 2) {
            // Assign second message to last message if this is lastest message
            if (data.messageId === messages[0].id) {
              paramsRoom.lastMessage = _omit(messages[1], ['id']);
            }
          } else {
            // If this is final message, clear lastMessage
            paramsRoom.lastMessage = {
              createdAt: roomData.createdAt,
              updatedAt: roomData.createdAt,
              type: MESSAGE_TYPE.TEXT,
              value: '',
            };
          }
          transaction.update(roomCollection, {
            deletedMessageId: data.messageId,
            updatedAt: timeStamp,
            ...numberOfUnreadMessageFields,
            ...paramsRoom,
          });

          return '';
        });
        resolve('');
      } catch (err) {
        reject(err);
      }
    })();
  });
};
