import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import Helpers from 'core/utils/helpers';
import i18n from 'i18n';
import {
  // PLOP: Add slice import below -- DO NOT ERASE
  addAdminUserRequest,
  addAdminUserSuccess,
  addAdminUserFailure,
  getAdminUserListRequest,
  getAdminUserListSuccess,
  getAdminUserListFailure,
  editAdminUserRequest,
  editAdminUserSuccess,
  editAdminUserFailure,
  changeAdminUserStatusRequest,
  changeAdminUserStatusSuccess,
  changeAdminUserStatusFailure,
  getAdminUserListForSelectRequest,
  getAdminUserListForSelectSuccess,
} from './slice';
import Service from './service';

export function* getAdminUserList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit']);
    const res = yield Service.list(params);
    yield put(getAdminUserListSuccess(res.data));
  } catch (error) {
    yield put(getAdminUserListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* addAdminUser(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name', 'email', 'password', 'role']);
    yield Service.add(params);
    yield put(addAdminUserSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:createSuccess'), 'success');
    yield put(getAdminUserListRequest(payload.searchParams));
  } catch (error) {
    yield put(addAdminUserFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* editAdminUser(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name', 'email', 'password', 'role']);
    yield Service.edit(payload.id, params);
    yield put(editAdminUserSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
    yield put(getAdminUserListRequest(payload.searchParams));
  } catch (error) {
    yield put(editAdminUserFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* changeAdminUserStatus(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['newStatus']);
    yield Service.changeStatus(payload.id, params);
    yield put(changeAdminUserStatusSuccess());
    yield put(getAdminUserListRequest(payload.searchParams));
    Helpers.toastr('', i18n.t('adminUser:changedStatusSuccessfully'), 'success');
  } catch (error) {
    yield put(changeAdminUserStatusFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getAdminUserListForSelect(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit']);
    const res = yield Service.list(params);
    yield put(getAdminUserListForSelectSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* adminUserWatch(): Generator {
  yield takeLeading(addAdminUserRequest.type, addAdminUser);
  yield takeEvery(getAdminUserListRequest.type, getAdminUserList);
  yield takeLeading(editAdminUserRequest.type, editAdminUser);
  yield takeLeading(changeAdminUserStatusRequest.type, changeAdminUserStatus);
  yield takeEvery(getAdminUserListForSelectRequest.type, getAdminUserListForSelect);
}
