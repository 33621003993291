import { AnyAction } from 'redux';
import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import Helpers from 'core/utils/helpers';
import {
  deleteContactRequest,
  deleteContactSuccess,
  getContactDetailRequest,
  getContactDetailSuccess,
  getContactListRequest,
  getContactListSuccess,
} from './slice';
import Services from './services';
import { TContact } from './types';

export function* getContactList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Services.getContactListApi(payload.searchParams);
    yield put(getContactListSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getContactDetail(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Services.getContactListApi(payload.searchParams);
    const data = (res.data.docs as TContact[]).find((doc) => doc._id === payload._id);
    yield put(getContactDetailSuccess({ data }));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* deleteContact(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Services.deleteContactApi(payload.id);
    yield put(deleteContactSuccess(res.data));
    yield put(getContactListRequest(payload.searchParams));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* watchContact(): Generator {
  yield takeEvery(getContactListRequest.type, getContactList);
  yield takeLeading(getContactDetailRequest.type, getContactDetail);
  yield takeLeading(deleteContactRequest.type, deleteContact);
}
