import { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { ObjectType } from 'admin-console-library';

import { IParams } from 'core/utils/interfaces';
import { useAppSelector, useAppDispatch } from 'store';
interface Options {
  providerName?: string;
}

const useFetchDetail = (
  action?: (payload) => AnyAction,
  options?: Options,
): { detail: ObjectType; loading: boolean | any } => {
  const dispatch = useAppDispatch();
  const { id } = useParams<IParams>();

  const providerName = _get(options, 'providerName', 'general');

  const detail = useAppSelector((state) => state[providerName].detail);
  const loading = useAppSelector((state) => state[providerName].loading);

  useEffect(() => {
    if (action && id) {
      dispatch(action({ id }));
    }
  }, [action, id]);

  return {
    detail,
    loading,
  };
};

export default useFetchDetail;
