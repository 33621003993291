import { useEffect, FunctionComponent } from 'react';
import { useHistory, Route, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helper from 'core/utils/helpers';
import DashboardPage from 'containers/Layout';

interface PrivateRoutesProps {
  exact?: boolean;
  path: string;
  component: FunctionComponent<RouteComponentProps>;
  notAllowRoles?: string[];
  currentUserRole?: string;
}

const PrivateRoutes = ({
  exact,
  path,
  component,
  notAllowRoles,
  currentUserRole,
}: PrivateRoutesProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const token = Helper.getAuthToken();
  useEffect(() => {
    if (!token) {
      window.location.href = '/login';
    }
  }, [token, history]);
  useEffect(() => {
    if (currentUserRole) {
      if (notAllowRoles && notAllowRoles.includes(currentUserRole) === true) {
        Helper.toastr('', t('global:FORBIDDEN'), 'error');
        history.push('/');
      }
    }
  }, [currentUserRole, history]);
  return (
    <DashboardPage path={path}>
      <Route exact={exact} path={path} component={component} />
    </DashboardPage>
  );
};

export default PrivateRoutes;
