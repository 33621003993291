import { put, takeEvery, takeLeading, call, take, select, race } from 'redux-saga/effects';
import _pick from 'lodash/pick';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import Helpers from 'core/utils/helpers';
import i18n from 'i18n';
import {
  handleListenUserDetail,
  handleLoginFirebase,
  handleLogoutFirebase,
} from 'services/firebase';
import service from './service';
import {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  setNewPasswordRequest,
  setNewPasswordSuccess,
  setNewPasswordError,
  listenUserDetailSuccess,
  listenUserDetailRequest,
  listenUserDetailRemove,
} from './slice';

function* handleLogin(action) {
  try {
    const { payload } = action;

    const params = _pick(payload, ['email', 'password']);
    const { data } = yield service.login(params);
    const token = _get(data, 'token', '');
    Helpers.storeAuthToken(token);
    yield put(loginSuccess());
    yield put(push('/'));
    yield put(getCurrentUserRequest());
  } catch (error) {
    yield put(loginError());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* handleLogout(): Generator {
  try {
    Helpers.removeAuthToken();
    yield call(handleLogoutFirebase);
    yield put(listenUserDetailRemove());
    window.location.href = '/login';
  } catch (error) {
    Helpers.toastr('', _get(error, `message`), 'error');
  }
}

export function* getCurrentUser(): Generator | unknown {
  try {
    const res = yield service.getCurrentUser();
    const { isListenFirebaseAuth = false } = yield select((state) => state.auth);
    const userId = yield call(handleLoginFirebase);
    yield put(getCurrentUserSuccess({ ..._get(res, 'data'), firebaseUserId: userId }));
    if (!isListenFirebaseAuth) {
      yield put(listenUserDetailRequest({ userId }));
    }
  } catch (error) {
    if (error.status === 404) {
      yield put(logoutRequest());
    }
    Helpers.toastr('', _get(error, `message`), 'error');
    getCurrentUserError();
  }
}

function* handleResetPassword(action) {
  try {
    const { payload } = action;

    const params = _pick(payload, ['email']);
    yield service.resetPassword(params);
    yield put(resetPasswordSuccess());
    Helpers.toastr('', i18n.t('resetPassword:anEmailHasBeenSent'), 'success');
    yield put(push('/login'));
  } catch (error) {
    yield put(resetPasswordError());
    Helpers.toastr('', error.message, 'error');
  }
}

function* handleSetNewPassword(action) {
  try {
    const { payload } = action;

    const params = _pick(payload, ['password', 'token']);
    yield service.setNewPassword(params);
    yield put(setNewPasswordSuccess());
    Helpers.toastr('', i18n.t('globally:successfully'), 'success');
    yield put(push('/login'));
  } catch (error) {
    yield put(setNewPasswordError());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* listenUserDetail(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const userDetailChannel = yield call(handleListenUserDetail, payload);
    try {
      while (true) {
        const { channelData, cancel } = yield race({
          channelData: take(userDetailChannel),
          cancel: take(listenUserDetailRemove.type),
        });
        if (cancel) {
          userDetailChannel.close();
        } else {
          yield put(listenUserDetailSuccess(channelData));
        }
      }
    } catch (error) {
      Helpers.toastr('', error.message, 'error');
    } finally {
      userDetailChannel.close();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* watchAuth(): Generator {
  yield takeLeading(loginRequest.type, handleLogin);
  yield takeLeading(logoutRequest.type, handleLogout);
  yield takeEvery(getCurrentUserRequest.type, getCurrentUser);
  yield takeLeading(resetPasswordRequest.type, handleResetPassword);
  yield takeLeading(setNewPasswordRequest.type, handleSetNewPassword);
  yield takeEvery(listenUserDetailRequest.type, listenUserDetail);
}
