import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import Helpers from 'core/utils/helpers';
import i18n from 'i18n';
import {
  // PLOP: Add slice import below -- DO NOT ERASE
  addUserRequest,
  addUserSuccess,
  addUserFailure,
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  changeUserStatusRequest,
  changeUserStatusSuccess,
  changeUserStatusFailure,
  getUserListForSelectRequest,
  getUserListForSelectSuccess,
} from './slice';
import Service from './service';

export function* getUserList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit']);
    const res = yield Service.list(payload.companyId, params);
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(getUserListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* addUser(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name', 'email', 'password', 'tel', 'role']);
    yield Service.add(payload.companyId, params);
    yield put(addUserSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:createSuccess'), 'success');
    yield put(getUserListRequest(payload.searchParams));
  } catch (error) {
    yield put(addUserFailure({ error }));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* editUser(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name', 'email', 'password', 'tel', 'role']);
    yield Service.edit(payload.companyId, payload.id, params);
    yield put(editUserSuccess());
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
    yield put(getUserListRequest(payload.searchParams));
  } catch (error) {
    yield put(editUserFailure({ error }));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* changeUserStatus(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['newStatus']);
    yield Service.changeStatus(payload.companyId, payload.id, params);
    yield put(changeUserStatusSuccess());
    yield put(getUserListRequest(payload.searchParams));
    Helpers.toastr('', i18n.t('adminUser:changedStatusSuccessfully'), 'success');
  } catch (error) {
    yield put(changeUserStatusFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getUserListForSelect(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit', 'sort']);
    const res = yield Service.list(payload.companyId, params);
    yield put(getUserListForSelectSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* userWatch(): Generator {
  yield takeLeading(addUserRequest.type, addUser);
  yield takeEvery(getUserListRequest.type, getUserList);
  yield takeLeading(editUserRequest.type, editUser);
  yield takeLeading(changeUserStatusRequest.type, changeUserStatus);
  yield takeEvery(getUserListForSelectRequest.type, getUserListForSelect);
}
