import { lazy, FunctionComponent } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
  ContainerOutlined,
  MessageOutlined,
  ProjectOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { SystemUserIcon } from 'admin-console-library';
import { ProfileIcon } from 'assets/icons';

const RedirectToFirstPage = (): JSX.Element => <Redirect to="/simulations" />;
const Login = lazy(() => import('containers/LoginPage'));
const ResetPassword = lazy(() => import('containers/ResetPasswordPage'));
const SetNewPassword = lazy(() => import('containers/SetNewPasswordPage'));
const AdminUserListPage = lazy(() => import('containers/AdminUser/List'));
const CompanyListPage = lazy(() => import('containers/Company/List'));
const UserListPage = lazy(() => import('containers/User/List'));
const SimulationRequestPage = lazy(() => import('containers/SimulationRequest/List'));
const SimulationDetailPage = lazy(() => import('containers/SimulationRequest/Detail'));
const MakeAProjectPage = lazy(() => import('containers/Project/MakeAProject'));
const ProjectListPage = lazy(() => import('containers/Project/List'));
const ProjectDetailPage = lazy(() => import('containers/Project/Detail'));
const MessageListListPage = lazy(() => import('containers/Message/List'));
const MessageDetailPage = lazy(() => import('containers/Message/Detail'));
const FreeConsultationPage = lazy(() => import('containers/FreeConsultation'));
const FreeConsultationDetailPage = lazy(() => import('containers/FreeConsultation/Detail'));
const SimulationRoiRequestPage = lazy(
  () => import('containers/SimulationRequest/SimulationRoiRequest'),
);
const NewsListPage = lazy(() => import('containers/News/List'));
const CreateNewsPage = lazy(() => import('containers/News/Create'));
const DetailNewsPage = lazy(() => import('containers/News/Detail'));

interface routeTypes {
  path: string;
  component: FunctionComponent<RouteComponentProps>;
  requireLogin?: boolean | undefined;
  exact?: boolean;
  menu?: boolean | undefined;
  name?: string | undefined;
  icon?: React.ReactNode;
  routes?:
    | Array<{
        path: string;
        name: string;
        icon: React.ReactNode;
      }>
    | undefined;
}

const routes: Array<routeTypes> = [
  {
    path: '/',
    component: RedirectToFirstPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/set-new-password',
    component: SetNewPassword,
    exact: true,
  },
  {
    path: '/simulations',
    component: SimulationRequestPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBarCustom:simulation',
    icon: <UnorderedListOutlined />,
  },
  {
    path: '/simulations/request',
    component: SimulationRoiRequestPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/projects',
    component: ProjectListPage,
    requireLogin: true,
    menu: true,
    name: 'project:menu',
    exact: true,
    icon: <ProjectOutlined />,
  },
  {
    path: '/projects/:id',
    component: ProjectDetailPage,
    requireLogin: true,
  },
  {
    path: '/messages',
    component: MessageListListPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBarCustom:message',
    icon: <MessageOutlined />,
  },
  {
    path: '/free-consultations',
    component: FreeConsultationPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBarCustom:freeConsultation',
    icon: (
      <span role="img" aria-label="consultation" className="anticon anticon-consultation">
        <ProfileIcon width={'1em'} height={'1em'} />
      </span>
    ),
  },
  {
    path: '/free-consultations/:id',
    component: FreeConsultationDetailPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/companies',
    component: CompanyListPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBarCustom:company',
    icon: <TeamOutlined />,
  },
  {
    path: '/news',
    component: NewsListPage,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBarCustom:news',
    icon: <ContainerOutlined />,
  },
  {
    path: '/news/create',
    component: CreateNewsPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/news/edit/:id',
    component: DetailNewsPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/admin-user',
    component: AdminUserListPage,
    requireLogin: true,
    exact: true,
    // For menu layout
    menu: true,
    name: 'menuBarCustom:adminUser',
    icon: <SystemUserIcon />,
  },

  {
    path: '/companies/:companyId/users',
    component: UserListPage,
    requireLogin: true,
    exact: true,
  },

  {
    path: '/simulations/:id',
    component: SimulationDetailPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/simulations/:id/make-a-project',
    component: MakeAProjectPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/messages/:id',
    component: MessageDetailPage,
    requireLogin: true,
    exact: true,
  },

  // PLOP: Add routes import below -- DO NOT ERASE
];

export default routes;
