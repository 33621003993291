import { createSlice } from '@reduxjs/toolkit';
import { ObjectType } from 'core/utils/types';

export interface ProjectState {
  loading: boolean;
  list: unknown[];
  total: number;
  addLoading: boolean;
  detail: ObjectType;
  isUpdatingDirectionCost: boolean;
  updateDirectionCostError: string;
  changeStatusLoading: boolean;
  updateCommentLoading: boolean;
  uploadFileLoading: Record<string, boolean>;
  removeFileLoading: Record<string, boolean>;
  errorChangeStatus: string;
}

export interface IPresidentUser {
  _id: string;
  company: string;
  createdAt: string;
  email: string;
  name: string;
  role: {
    _id: string;
  };
}

const initialState: ProjectState = {
  addLoading: false,
  loading: false,
  list: [],
  total: 0,
  detail: {},
  isUpdatingDirectionCost: false,
  updateDirectionCostError: '',
  changeStatusLoading: false,
  updateCommentLoading: false,
  uploadFileLoading: {},
  removeFileLoading: {},
  errorChangeStatus: '',
};

const ProjectProvider = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addProjectRequest(state, action) {
      state.addLoading = true;
      return state;
    },
    addProjectSuccess(state) {
      state.addLoading = false;
      return state;
    },
    addProjectFailure(state, action) {
      state.addLoading = false;
      return state;
    },
    getProjectListRequest(state, action) {
      state.loading = true;
      return state;
    },
    getProjectListSuccess(state, action) {
      const { docs, totalDocs } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      return state;
    },
    getProjectListFailure(state) {
      state.loading = false;
      return state;
    },
    getProjectDetailRequest(state, action) {
      state.loading = true;
      return state;
    },
    getProjectDetailSuccess(state, action) {
      state.detail = action.payload;
      state.loading = false;
      return state;
    },
    getProjectDetailFailure(state) {
      state.loading = false;
      return state;
    },
    updateDirectionCostRequest(state, action) {
      state.isUpdatingDirectionCost = true;
      state.updateDirectionCostError = '';
      return state;
    },
    updateDirectionCostSuccess(state) {
      state.isUpdatingDirectionCost = false;
      state.updateDirectionCostError = '';
      return state;
    },
    updateDirectionCostFailure(state, action) {
      state.isUpdatingDirectionCost = false;
      state.updateDirectionCostError = action.payload;
    },
    changeProjectStatusRequest(state, action) {
      state.changeStatusLoading = true;
      state.errorChangeStatus = '';
      return state;
    },
    changeProjectStatusSuccess(state) {
      state.changeStatusLoading = false;
      return state;
    },
    changeProjectStatusFailure(state, action) {
      state.changeStatusLoading = false;
      state.errorChangeStatus = action.payload;
      return state;
    },
    addFileToProjectRequest(state, action) {
      return state;
    },
    removeFileFromProjectRequest(state, action) {
      state.removeFileLoading[action.payload.key] = true;
      return state;
    },
    removeFileFromProjectFailure(state, action) {
      state.removeFileLoading[action.payload.key] = false;
      return state;
    },
    updateCommentRequest(state, action) {
      state.updateCommentLoading = true;
      return state;
    },
    updateCommentSuccess(state) {
      state.updateCommentLoading = false;
      return state;
    },
    updateCommentFailure(state) {
      state.updateCommentLoading = false;
      return state;
    },
    uploadFileLoadingRequest(state, action) {
      state.uploadFileLoading[action.payload.type] = true;
      return state;
    },
    uploadFileLoadingSuccess(state, action) {
      state.uploadFileLoading[action.payload.type] = false;
      return state;
    },
    uploadFileLoadingFailure(state, action) {
      state.uploadFileLoading[action.payload.type] = false;
      return state;
    },
    resetLoadingRequest(state) {
      state.uploadFileLoading = {};
      state.removeFileLoading = {};
      state.errorChangeStatus = '';
      return state;
    },
    downLoadFileProjectRequest(state, action) {
      return state;
    },
  },
});

export const {
  getProjectListRequest,
  getProjectListSuccess,
  getProjectListFailure,
  addProjectRequest,
  addProjectSuccess,
  addProjectFailure,
  getProjectDetailRequest,
  getProjectDetailSuccess,
  getProjectDetailFailure,
  updateDirectionCostRequest,
  updateDirectionCostSuccess,
  updateDirectionCostFailure,
  changeProjectStatusRequest,
  changeProjectStatusSuccess,
  changeProjectStatusFailure,
  addFileToProjectRequest,
  removeFileFromProjectRequest,
  removeFileFromProjectFailure,
  updateCommentRequest,
  updateCommentSuccess,
  updateCommentFailure,
  uploadFileLoadingRequest,
  uploadFileLoadingSuccess,
  uploadFileLoadingFailure,
  resetLoadingRequest,
  downLoadFileProjectRequest,
} = ProjectProvider.actions;

export default ProjectProvider.reducer;
