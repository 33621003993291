import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';

class Message extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getFirebaseToken = (): Promise<AxiosResponse> => {
    return this.post('/firebase/operator/auth/access-token', {});
  };

  sendMail = (
    simulationId: string,
    params: { message: string; roomType: string },
  ): Promise<AxiosResponse> => {
    return this.post(`/mail/common/simulation-request/${simulationId}/notifications`, params);
  };

  getDownLoadFile = (params): Promise<AxiosResponse> => {
    return this.post(`/media/operator/chat-room/documents/downloading-url`, params);
  };
}

const service = new Message('');
export default service;
