import { put, takeEvery, takeLeading, takeLatest, delay } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import Helpers from 'core/utils/helpers';
import i18n from 'i18n';
import {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyFailure,
  getCompanyListRequest,
  getCompanyListSuccess,
  getCompanyListFailure,
  editCompanyRequest,
  editCompanySuccess,
  editCompanyFailure,
  deleteCompanyRequest,
  deleteCompanySuccess,
  deleteCompanyFailure,
  getCompanyListForSelectRequest,
  getCompanyListForSelectSuccess,
} from './slice';
import Service from './service';

export function* getCompanyList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit']);
    const res = yield Service.list(params);
    yield put(getCompanyListSuccess(res.data));
  } catch (error) {
    yield put(getCompanyListFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* addCompany(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name']);
    yield Service.add(params);
    yield put(addCompanySuccess());
    Helpers.toastr('', i18n.t('toastrMessage:createSuccess'), 'success');
    yield put(getCompanyListRequest(payload.searchParams));
  } catch (error) {
    yield put(addCompanyFailure({ error }));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* editCompany(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['name']);
    yield Service.edit(payload.id, params);
    yield put(editCompanySuccess());
    Helpers.toastr('', i18n.t('toastrMessage:updateSuccess'), 'success');
    yield put(getCompanyListRequest(payload.searchParams));
  } catch (error) {
    yield put(editCompanyFailure({ error }));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* deleteCompany(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['newStatus']);
    yield Service.deleteCompany(payload.id, params);
    yield put(deleteCompanySuccess());
    yield put(getCompanyListRequest(payload.searchParams));
    Helpers.toastr('', i18n.t('globally:deleteSuccessfully'), 'success');
  } catch (error) {
    yield put(deleteCompanyFailure());
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getCompanyListForSelect(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit', 'keyword']);
    yield delay(500);
    const res = yield Service.list(params);
    yield put(getCompanyListForSelectSuccess(res.data));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export default function* companyWatch(): Generator {
  yield takeLeading(addCompanyRequest.type, addCompany);
  yield takeEvery(getCompanyListRequest.type, getCompanyList);
  yield takeLeading(editCompanyRequest.type, editCompany);
  yield takeLeading(deleteCompanyRequest.type, deleteCompany);
  yield takeLatest(getCompanyListForSelectRequest.type, getCompanyListForSelect);
}
