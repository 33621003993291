import { createSlice } from '@reduxjs/toolkit';
import { TNews } from './types';
export interface NewsState {
  loading: boolean;
  list: unknown[];
  total: number;
  error: string | null;
  page: number;
  details: TNews | undefined;
}

const initialState: NewsState = {
  loading: false,
  list: [],
  total: 0,
  error: null,
  page: 0,
  details: undefined,
};

const NewsProvider = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // Reset news state
    resetNewsState(state) {
      state.loading = false;
      state.list = [];
      state.total = 0;
      state.error = null;
      state.page = 0;
      state.details = undefined;
      return state;
    },
    // Get news list
    getNewsListRequest(state, _action) {
      state.loading = true;
      state.error = null;
      return state;
    },
    getNewsListSuccess(state, action) {
      const { docs, totalDocs, page } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      state.page = page;
      state.error = null;
      return state;
    },
    getNewsListFailure(state, action) {
      const { payload } = action;
      state.loading = false;
      state.error = payload.message;
      return state;
    },
    // Get detail list
    getNewsDetailRequest(state, _action) {
      state.loading = true;
      state.error = null;
      return state;
    },
    getNewsDetailSuccess(state, action) {
      state.loading = false;
      state.details = action.payload;
      state.error = null;
      return state;
    },
    getNewsDetailFailure(state, action) {
      const { payload } = action;
      state.loading = false;
      state.error = payload.message;
      return state;
    },
    // Create news
    createNewsRequest(state, _action) {
      state.loading = true;
      state.error = null;
      return state;
    },
    createNewsSuccess(state, action) {
      state.loading = false;
      state.list = [action.payload, ...state.list];
      state.total += 1;
      state.error = null;
      return state;
    },
    createNewsFailure(state, action) {
      const { payload } = action;
      state.loading = false;
      state.error = payload.message;
      return state;
    },
    // Delete News
    deleteNewsRequest(state, _action) {
      state.loading = true;
      state.error = null;
      return state;
    },
    deleteNewsSuccess(state, _action) {
      state.loading = false;
      state.error = null;
      return state;
    },
    deleteNewsFailure(state, action) {
      const { payload } = action;
      state.loading = false;
      state.error = payload.message;
      return state;
    },
    // Edit News
    editNewsRequest(state, _action) {
      state.loading = true;
      state.error = null;
      return state;
    },
    editNewsSuccess(state, action) {
      state.loading = false;
      state.details = action.payload;
      state.error = null;
      return state;
    },
    editNewsFailure(state, action) {
      const { payload } = action;
      state.loading = false;
      state.error = payload.message;
      return state;
    },
  },
});

export const {
  getNewsListRequest,
  getNewsListFailure,
  getNewsListSuccess,
  createNewsRequest,
  createNewsSuccess,
  createNewsFailure,
  deleteNewsRequest,
  deleteNewsSuccess,
  deleteNewsFailure,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  getNewsDetailFailure,
  resetNewsState,
  editNewsRequest,
  editNewsSuccess,
  editNewsFailure,
} = NewsProvider.actions;

export default NewsProvider.reducer;
