import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { replace } from 'connected-react-router';
import _pick from 'lodash/pick';
import { toast } from 'react-toastify';
import Helpers from 'core/utils/helpers';
import { NEWS_STATUS } from 'utils/constants';
import i18n from 'i18n';
import {
  getNewsListRequest,
  getNewsListFailure,
  getNewsListSuccess,
  createNewsRequest,
  createNewsSuccess,
  deleteNewsRequest,
  deleteNewsSuccess,
  deleteNewsFailure,
  createNewsFailure,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  getNewsDetailFailure,
  editNewsRequest,
  editNewsSuccess,
  editNewsFailure,
} from './slice';
import Service from './service';

export function* getNewsList(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['page', 'limit']);
    const res = yield Service.list({
      ...params,
      status: `${NEWS_STATUS.ACTIVE},${NEWS_STATUS.INACTIVE}`,
    });
    yield put(
      getNewsListSuccess({
        ...res.data,
        params,
      }),
    );
  } catch (error) {
    yield put(getNewsListFailure(error));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* createNews(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['title', 'content']);
    const res = yield Service.create(params);
    yield put(createNewsSuccess(res.data));
    yield put(replace('/news'));
    Helpers.toastr('', i18n.t('globally:createSuccessfully'), 'success');
  } catch (error) {
    yield put(createNewsFailure(error));
    Helpers.toastr('', i18n.t('globally:somethingWentWrong'), 'error');
  }
}

export function* editNews(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['title', 'content', 'status', '_id']);
    const res = yield Service.edit(params);
    yield put(editNewsSuccess(res.data));
    yield put(replace('/news'));
    Helpers.toastr('', i18n.t('globally:editSuccessfully'), 'success');
  } catch (error) {
    yield put(editNewsFailure(error));
    Helpers.toastr('', i18n.t('globally:somethingWentWrong'), 'error');
  }
}

export function* deleteNews(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['title', 'content', 'status', '_id']);
    const res = yield Service.delete(params);
    const newList = yield Service.list(payload.searchParams);
    if (res.data) {
      yield put(deleteNewsSuccess(res.data));
      yield put(
        getNewsListSuccess({
          ...newList.data,
          params,
        }),
      );
    }
  } catch (error) {
    yield put(deleteNewsFailure(error));
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getNewsDetail(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const res = yield Service.detail(payload);
    yield put(getNewsDetailSuccess(res.data));
  } catch (error) {
    yield put(getNewsDetailFailure(error));
    Helpers.toastr('', error.message, 'error');
    yield put(replace('/404-not-found'));
  }
}

export default function* newsWatch(): Generator {
  yield takeEvery(getNewsListRequest.type, getNewsList);
  yield takeLeading(createNewsRequest.type, createNews);
  yield takeLeading(deleteNewsRequest.type, deleteNews);
  yield takeLeading(editNewsRequest.type, editNews);
  yield takeEvery(getNewsDetailRequest.type, getNewsDetail);
}
