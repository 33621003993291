import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  loading: boolean;
  list: unknown[];
  total: number;
  addLoading: boolean;
  editLoading: boolean;
  changeUserStatusLoading: boolean;
  error: string | null;
  userListForSelect: [];
}

const initialState: UserState = {
  addLoading: false,
  loading: false,
  list: [],
  total: 0,
  editLoading: false,
  changeUserStatusLoading: false,
  error: null,
  userListForSelect: [],
};

const UserProvider = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserRequest(state, action) {
      state.addLoading = true;
      state.error = null;
      return state;
    },
    addUserSuccess(state) {
      state.addLoading = false;
      state.error = null;
      return state;
    },
    addUserFailure(state, action) {
      const { payload } = action;
      state.addLoading = false;
      state.error = payload.error.message;
      return state;
    },
    editUserRequest(state, action) {
      state.editLoading = true;
      state.error = null;
      return state;
    },
    editUserSuccess(state) {
      state.editLoading = false;
      state.error = null;
      return state;
    },
    editUserFailure(state, action) {
      const { payload } = action;
      state.editLoading = false;
      state.error = payload.error.message;
      return state;
    },
    getUserListRequest(state, action) {
      state.loading = true;
      return state;
    },
    getUserListSuccess(state, action) {
      const { docs, totalDocs } = action.payload;
      state.loading = false;
      state.list = docs;
      state.total = totalDocs;
      return state;
    },
    getUserListFailure(state) {
      state.loading = false;
      return state;
    },
    changeUserStatusRequest(state, action) {
      state.changeUserStatusLoading = true;
      return state;
    },
    changeUserStatusSuccess(state) {
      state.changeUserStatusLoading = false;
      return state;
    },
    changeUserStatusFailure(state) {
      state.changeUserStatusLoading = false;
      return state;
    },
    getUserListForSelectRequest(state, action) {
      return state;
    },
    getUserListForSelectSuccess(state, action) {
      const { docs } = action.payload;
      state.userListForSelect = docs;
      return state;
    },
  },
});

export const {
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  addUserRequest,
  addUserSuccess,
  addUserFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  changeUserStatusRequest,
  changeUserStatusSuccess,
  changeUserStatusFailure,
  getUserListForSelectRequest,
  getUserListForSelectSuccess,
} = UserProvider.actions;

export default UserProvider.reducer;
