import { AxiosResponse } from 'axios';
import apiInstance from 'core/utils/api';

class ContactServices extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getContactListApi = (params): Promise<AxiosResponse> =>
    this.get('/accounts/operator/contact', params);

  deleteContactApi = (id: string): Promise<AxiosResponse> =>
    this.delete(`/accounts/operator/contact/${id}`);
}

const services = new ContactServices('');

export default services;
