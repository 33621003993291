import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  collection,
  doc,
  DocumentData,
  documentId,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import { AnyAction } from 'redux';
import _pick from 'lodash/pick';
import Helpers from 'core/utils/helpers';
import {
  handleListenRoomDetail,
  handleSendMessage,
  db,
  handleListenConversationList,
  handleListenMessage,
  handleReadRoom,
  handleDeleteMessage,
  handleListenConversationUpdated,
} from 'services/firebase';
import { CONVERSATION_LIMIT, FIREBASE_DOC_STATUS, MESSAGE_LIMIT } from 'utils/constants';
import Helper from 'utils/helpers';
import {
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageFailure,
  listenRoomRequest,
  unsubscribeRoom,
  listenRoomSuccess,
  getConversationListRequest,
  getConversationListSuccess,
  getConversationListError,
  listenConversationListRequest,
  listenConversationListRemove,
  listenMessageRequest,
  unsubscribeMessage,
  listenMessageSuccess,
  getUsersInfoRequest,
  getUsersInfoSuccess,
  getMoreMessageRequest,
  getMoreMessageSuccess,
  downLoadFileMessageRequest,
  readRoomRequest,
  readRoomSuccess,
  deleteMessageRequest,
  listenConversationListSuccess,
  listenConversationUpdatedRequest,
  listenConversationUpdatedSuccess,
  IMessageId,
} from './slice';
import Service from './service';
import { IConversationItem } from './interfaces';

export function* sendMessage(action: AnyAction): unknown {
  try {
    const { payload } = action;
    yield call(
      handleSendMessage,
      _pick(payload, [
        'senderId',
        'text',
        'roomId',
        'type',
        'value',
        'readingUsers',
        'currentMembers',
        'extraInfo',
      ]),
    );
    if (payload.isSendEmail) {
      yield Service.sendMail(payload.simulationRequestId, {
        message: payload.value,
        roomType: payload.roomType.toLowerCase(),
      });
    }
    yield put(sendMessageSuccess());
  } catch (error) {
    yield put(sendMessageFailure());
    Helpers.toastr('', error.message, 'error');
  }
}
export function* listenRoom(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const roomChannel = yield call(handleListenRoomDetail, payload);
    try {
      while (true) {
        const { channelData, cancel } = yield race({
          channelData: take(roomChannel),
          cancel: take(unsubscribeRoom.type),
        });
        if (cancel) {
          roomChannel.close();
        } else {
          yield put(listenRoomSuccess(channelData));
        }
      }
    } catch (error) {
      Helpers.toastr('', error.message, 'error');
    } finally {
      roomChannel.close();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* handleGetConversationListSaga(): unknown {
  const { lastVisible } = yield select((state) => state.message);
  const { currentUser = {} } = yield select((state) => state.auth);
  try {
    const queryDocs = query(
      collection(db, 'rooms'),
      where('currentMembers', 'array-contains', currentUser.firebaseUserId),
      where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
      orderBy('lastMessage.updatedAt', 'desc'),
      startAfter(lastVisible || new Date()),
      limit(CONVERSATION_LIMIT),
    );
    const conversationIds: string[] = [];
    const conversations = {};
    let nextCursor: QueryDocumentSnapshot<IConversationItem> | undefined;
    const querySnapshot: QuerySnapshot<IConversationItem> = yield getDocs(queryDocs);
    querySnapshot.docs.forEach((doc, index) => {
      const documentId = doc.id;
      conversations[documentId] = doc.data();
      conversationIds.push(documentId);
      if (index === querySnapshot.docs.length - 1) {
        nextCursor = doc;
      }
    });
    yield put(
      getConversationListSuccess({
        conversationIds,
        conversations,
        lastVisible: nextCursor,
      }),
    );
  } catch (error) {
    yield put(getConversationListError());
  }
}

function* listenConversationSaga() {
  const { currentUser = {} } = yield select((state) => state.auth);
  const { firstVisible } = yield select((state) => state.message);
  const conversationChannel = yield call(handleListenConversationList, {
    userId: currentUser.firebaseUserId,
    before: firstVisible,
  });
  try {
    while (true) {
      const { channelData, cancel } = yield race({
        channelData: take(conversationChannel),
        cancel: take(listenConversationListRemove.type),
      });
      if (cancel) {
        conversationChannel.close();
      } else {
        const { conversationIds, conversations } = channelData;
        yield put(
          listenConversationListSuccess({
            conversationIds,
            conversations,
            lastVisible:
              conversationIds.length > 0
                ? conversations[conversationIds[conversationIds.length - 1]].lastMessage.updatedAt
                : null,
          }),
        );
      }
    }
  } catch (e) {
    yield put(getConversationListError());
  } finally {
    conversationChannel.close();
  }
}

export function* listenMessage(action: AnyAction): unknown {
  try {
    const { currentUser = {} } = yield select((state) => state.auth);
    const { payload } = action;
    const messageChannel = yield call(handleListenMessage, payload);
    let firstFetch = true;
    try {
      while (true) {
        const { messages, cancel } = yield race({
          messages: take(messageChannel),
          cancel: take(unsubscribeMessage.type),
        });
        if (cancel) {
          messageChannel.close();
        } else {
          yield put(listenMessageSuccess({ ...messages, firstFetch }));
          if (!firstFetch) {
            const { messageData, messageIds } = messages;
            if (
              messageIds[0] &&
              messageData[messageIds[0].id] &&
              messageData[messageIds[0].id].senderId !== currentUser.firebaseUserId
            ) {
              yield put(readRoomRequest({ roomId: payload, senderId: currentUser.firebaseUserId }));
            }
          }
          firstFetch = false;
        }
      }
    } catch (error) {
      Helpers.toastr('', error.message, 'error');
    } finally {
      messageChannel.close();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* getUsersInfo(action: AnyAction): unknown {
  try {
    const batches: Promise<QuerySnapshot<DocumentData>>[] = [];
    const userIds = [...action.payload];
    while (userIds.length) {
      batches.push(
        getDocs(query(collection(db, 'users'), where(documentId(), 'in', userIds.splice(0, 10)))),
      );
    }
    const queryBatches = yield Promise.all(batches);

    const users = {};
    queryBatches.forEach((queryBatch) => {
      queryBatch.forEach((doc) => {
        users[doc.id] = doc.data();
      });
    });

    yield put(getUsersInfoSuccess(users));
  } catch (error) {}
}

export function* getMoreMessage(action: AnyAction): unknown {
  try {
    const { roomId, lastMessageTime } = action.payload;
    const queryMessages = yield getDocs(
      query(
        collection(doc(collection(db, 'rooms'), roomId), 'messages'),
        where('status', '==', FIREBASE_DOC_STATUS.ACTIVE),
        orderBy('createdAt', 'desc'),
        startAfter(lastMessageTime),
        limit(MESSAGE_LIMIT),
      ),
    );
    const messageIds: IMessageId[] = [];
    const messageData = {};
    queryMessages.forEach((doc) => {
      const data = doc.data();
      messageIds.push({
        id: doc.id,
        createdAt: data.createdAt.toDate(),
      });
      messageData[doc.id] = data;
    });

    yield put(getMoreMessageSuccess({ messageIds, messageData }));
  } catch (error) {}
}

export function* downLoadFile(action: AnyAction): unknown {
  try {
    const { payload } = action;
    const params = _pick(payload, ['key']);
    const res = yield Service.getDownLoadFile(params);
    if (Helper.isIphone()) {
      window.location.assign(res.data.signedUrl);
    } else {
      const link = document.createElement('a');
      link.href = res.data.signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}
export function* readRoom(action: AnyAction): unknown {
  try {
    const { payload } = action;
    delay(1000);
    yield call(handleReadRoom, _pick(payload, ['roomId', 'senderId']));
    yield put(
      readRoomSuccess({
        roomId: payload.roomId,
        senderId: payload.senderId,
      }),
    );
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

export function* deleteMessage(action: AnyAction): unknown {
  try {
    const { payload } = action;
    delay(500);
    yield call(handleDeleteMessage, _pick(payload, ['roomId', 'messageId']));
  } catch (error) {
    Helpers.toastr('', error.message, 'error');
  }
}

function* listenConversationUpdated() {
  const { currentUser = {} } = yield select((state) => state.auth);
  const conversationChannel = yield call(handleListenConversationUpdated, {
    userId: currentUser.firebaseUserId,
  });
  try {
    while (true) {
      const { channelData, cancel } = yield race({
        channelData: take(conversationChannel),
        cancel: take(listenConversationListRemove.type),
      });
      if (cancel) {
        conversationChannel.close();
      } else {
        const { conversations } = channelData;
        yield put(
          listenConversationUpdatedSuccess({
            conversations,
          }),
        );
      }
    }
  } catch (e) {
  } finally {
    conversationChannel.close();
  }
}

export default function* messageWatch(): Generator {
  yield takeLeading(sendMessageRequest.type, sendMessage);
  yield takeEvery(listenRoomRequest.type, listenRoom);
  yield takeEvery(listenConversationListRequest.type, listenConversationSaga);
  yield takeLeading(getConversationListRequest.type, handleGetConversationListSaga);
  yield takeEvery(listenMessageRequest.type, listenMessage);
  yield takeEvery(getUsersInfoRequest.type, getUsersInfo);
  yield takeEvery(getMoreMessageRequest.type, getMoreMessage);
  yield takeLeading(downLoadFileMessageRequest.type, downLoadFile);
  yield takeLatest(readRoomRequest.type, readRoom);
  yield takeLatest(deleteMessageRequest.type, deleteMessage);
  yield takeLatest(listenConversationUpdatedRequest.type, listenConversationUpdated);
}
