import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Layout as LayoutPage, NotificationItem, NotificationProps } from 'admin-console-library';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useHistory } from 'react-router-dom';
import { logoutRequest } from 'providers/AuthProvider/slice';
import i18n from 'i18n';
import './styles.less';
import { useAppDispatch, useAppSelector } from 'store';
import { langOptions } from 'utils/constants';
import { indexRoutes, Route } from 'core/routes';
import { changeLanguage } from 'core/providers/LangProvider/slice';
import { useFetchListNotChangeSearchParam, useFetchUser } from 'core/hooks';
import {
  getNotificationListRequest,
  markReadAllRequest,
  markReadItemRequest,
  resetNotificationList,
} from 'providers/NotificationProvider/slice';
import { useWindowDimensions } from 'hooks';

const LayoutContainer = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element => {
  const { width } = useWindowDimensions();
  const isOnSmallScreen = width <= 850;
  const dispatch = useAppDispatch();
  const { currentUser } = useFetchUser();
  const { t } = useTranslation('alert');
  const history = useHistory();
  const { location } = history;
  const lang = useAppSelector((state) => state.lang);
  const { realTimeCurrentUser } = useAppSelector((state) => state.auth);
  const [collapse, setCollapse] = useState(isOnSmallScreen);

  const { list, params, total, loading, loadList } = useFetchListNotChangeSearchParam(
    getNotificationListRequest,
    {
      providerName: 'notification',
    },
  );
  const isShowDot = useAppSelector((state) => state.notification.isShowDot);

  const { page } = params;

  const loadDataNotification = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(resetNotificationList());
      loadList({ page: 1 });
    }
  };

  const loadMoreNotification = () => {
    loadList({ page: page + 1 });
  };

  const markReadAll = () => {
    dispatch(markReadAllRequest());
  };

  const handleClickItem = (data: NotificationItem) => {
    dispatch(markReadItemRequest(data));
  };

  const notiProps: NotificationProps = {
    items: list as NotificationItem[],
    isLoading: loading,
    totalItems: total,
    height: 300,
    isShowDot: isShowDot,
    titleText: t('notification:notificationTitleText'),
    markReadText: t('notification:notificationMarkReadText'),
    loadingText: t('notification:notificationLoadingText'),
    loadedText: t('notification:notificationLoadedText'),
    noDataText: t('notification:notificationNoDataText'),
    handleLoadMore: loadMoreNotification,
    handleOpenNoti: loadDataNotification,
    handleClickMarkRead: markReadAll,
    handleClickItem: handleClickItem,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routes = [] as any;
  // add translation for menu
  indexRoutes.forEach((itemRoute) => {
    const itemRouteCustom = { ...itemRoute };
    if (itemRoute.name) {
      itemRouteCustom.name = t(itemRoute.name);
    }
    if (itemRoute.routes) {
      itemRoute.routes.forEach((itemSubRoute, index) => {
        if (itemSubRoute.name) {
          _set(itemRouteCustom, `routes.${index}.name`, t(itemSubRoute.name));
        }
      });
    }
    routes.push(itemRouteCustom);
  });

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const handleChangeLang = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };

  const handleClickLeftMenuItem = (pathValue) => {
    history.push(pathValue);
  };

  const menuHeader = [
    {
      key: 'LOGOUT',
      label: t('common:logoutText'),
      func: handleLogout,
    },
  ];

  return (
    <LayoutPage
      siderWidth={225}
      currentUser={{
        picture: _get(currentUser, 'picture', ''),
        name: _get(currentUser, 'email', ''),
      }}
      pathname={location.pathname}
      route={{
        path: '/',
        routes: routes.filter((route: Route) => route.menu),
      }}
      lang={lang.current}
      langOptions={langOptions}
      logo={
        <a href="/" className="logo">
          <img src="/icon.png" alt="" />
        </a>
      }
      handleChangeLang={handleChangeLang}
      handleClickLeftMenuItem={handleClickLeftMenuItem}
      isShowNotification={false}
      notiProps={notiProps}
      menuHeader={menuHeader}
      collapsed={collapse}
      onCollapse={(values) => setCollapse(values)}
      menuItemRender={(item) => {
        return (
          <div
            className="custom-menu-item"
            role="presentation"
            onClick={() => handleClickLeftMenuItem(item.path)}
          >
            {item.icon}
            <span>{item.name}</span>
            {item?.path &&
            ['/messages'].includes(item.path) &&
            realTimeCurrentUser &&
            realTimeCurrentUser.numberOfUnreadMessage > 0 ? (
              <div className={`number-unread-message ${collapse ? ' collapsed' : ''}`}>
                {realTimeCurrentUser.numberOfUnreadMessage > 99
                  ? '99+'
                  : realTimeCurrentUser.numberOfUnreadMessage}
              </div>
            ) : null}
          </div>
        );
      }}
    >
      {children}
    </LayoutPage>
  );
};
export default LayoutContainer;
