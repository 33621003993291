import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';
import { AREA_SERVICE_URL } from 'core/utils/config';
import { ISendInformationRequest, ISimulationRequest } from './types';

class Simulation extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  list = (params): Promise<AxiosResponse> => {
    return this.get('/booking/operator/simulation-requests', { params });
  };

  detail = (id, params): Promise<AxiosResponse> => {
    return this.get(`/booking/operator/simulation-requests/${id}`, { params });
  };

  getOtherSimulations = (id, params): Promise<AxiosResponse> => {
    return this.get(`/booking/operator/simulation-requests/${id}/other-simulation-requests`, {
      params,
    });
  };

  getDownLoadFile = (id, params): Promise<AxiosResponse> => {
    return this.post(`/booking/operator/simulation-requests/${id}/documents`, params);
  };

  updateApproximateSimulation = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/simulation-requests/${id}/simple_roi_simulation`, params);
  };

  publishApproximateSimulation = (id): Promise<AxiosResponse> => {
    return this.put(
      `/booking/operator/simulation-requests/${id}/published_simple_roi_simulation`,
      {},
    );
  };

  updateDetailSimulation = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/simulation-requests/${id}/detail_roi_simulation`, params);
  };

  publishDetailSimulation = (id, params): Promise<AxiosResponse> => {
    return this.put(
      `/booking/operator/simulation-requests/${id}/detail-roi-simulation/send-to-user-flag`,
      params,
    );
  };

  getCategoriesList = (): Promise<AxiosResponse> => {
    return this.get('/accounts/customer/categories', {});
  };

  updateSimulation = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/simulation-requests/${id}`, params);
  };

  quickEditSimulation = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/simulation-requests/${id}/quick-edit`, params);
  };

  deleteSimulation = (id): Promise<AxiosResponse> => {
    return this.delete(`/booking/operator/simulation-requests/${id}`, {});
  };

  getPrefectureList = (): Promise<AxiosResponse> => {
    return this.get('/prefectures', { baseURL: AREA_SERVICE_URL });
  };

  getCityList = (prefectureId): Promise<AxiosResponse> => {
    return this.get(`/prefectures/${prefectureId}/cities`, {
      baseURL: AREA_SERVICE_URL,
      params: { limit: 200 },
    });
  };

  requestRoiSimulation = (data: ISimulationRequest): Promise<AxiosResponse> => {
    return this.post('/booking/operator/simulation-requests', data);
  };

  sendInformation = (data: ISendInformationRequest): Promise<AxiosResponse> => {
    return this.put('/accounts/operator/customers/resend-info', data);
  };

  updateMemosApi = (id: string, params: unknown): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/simulation-requests/${id}/memos`, params);
  };
}

const service = new Simulation('');
export default service;
