import { useEffect } from 'react';
import Helper from 'core/utils/helpers';
import { useAppDispatch, useAppSelector } from 'store';
import { getCurrentUserRequest } from 'providers/AuthProvider/slice';

const useFetchUser = (): { isLoadingcurrentUser: boolean; currentUser: unknown } => {
  const dispatch = useAppDispatch();
  const isLoadingcurrentUser = useAppSelector((state) => state.auth.isLoadingcurrentUser);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (Helper.getAuthToken()) {
      dispatch(getCurrentUserRequest());
    }
  }, []);

  return { isLoadingcurrentUser, currentUser };
};

export default useFetchUser;
