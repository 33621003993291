import { AxiosResponse } from 'axios';
import pick from 'lodash/pick';
import get from 'lodash/get';
import ApiInstance from 'core/utils/api';

class News extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  list = (params): Promise<AxiosResponse> => {
    return this.get('/news/operator/news', { params });
  };

  create = (params): Promise<AxiosResponse> => {
    return this.post('/news/operator/news', params);
  };

  delete = (params): Promise<AxiosResponse> => {
    const id = get(params, ['_id']);
    const data = pick(params, ['title', 'content', 'status']);
    return this.patch(`/news/operator/news/${id}`, data);
  };

  edit = (params): Promise<AxiosResponse> => {
    const id = get(params, ['_id']);
    const data = pick(params, ['title', 'content', 'status']);
    return this.patch(`/news/operator/news/${id}`, data);
  };

  detail = (id: string): Promise<AxiosResponse> => {
    return this.get(`/news/operator/news/${id}`);
  };
}

const service = new News('');
export default service;
