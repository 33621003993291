/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#212121"
        d="M12.75.75H1.25a.5.5 0 0 0-.5.5v11.5a.5.5 0 0 0 .5.5h11.5a.5.5 0 0 0 .5-.5V1.25a.5.5 0 0 0-.5-.5Zm-.625 11.375H1.875V1.875h10.25v10.25ZM6.687 5.25h2.875a.125.125 0 0 0 .125-.125v-.75a.125.125 0 0 0-.125-.125H6.687a.125.125 0 0 0-.125.125v.75c0 .069.057.125.125.125Zm0 2.25h2.875a.125.125 0 0 0 .125-.125v-.75a.125.125 0 0 0-.125-.125H6.687a.125.125 0 0 0-.125.125v.75c0 .069.057.125.125.125Zm0 2.25h2.875a.125.125 0 0 0 .125-.125v-.75a.125.125 0 0 0-.125-.125H6.687a.125.125 0 0 0-.125.125v.75c0 .069.057.125.125.125Zm-2.375-5a.625.625 0 1 0 1.25 0 .625.625 0 0 0-1.25 0Zm0 2.25a.625.625 0 1 0 1.25 0 .625.625 0 0 0-1.25 0Zm0 2.25a.625.625 0 1 0 1.25 0 .625.625 0 0 0-1.25 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
