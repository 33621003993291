import { createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _set from 'lodash/set';
import moment from 'moment';
import { CONVERSATION_LIMIT, MESSAGE_LIMIT } from 'utils/constants';
import { IConversationItem, IMessageType, IRoomType, IUser } from './interfaces';
export interface IMessageId {
  id: string;
  createdAt: string;
}
export interface MessageState {
  // PLOP: Add state interface below -- DO NOT ERASE
  loading: boolean;
  list: unknown[];
  total: number;
  page: number;
  isShowDot: boolean;
  conversationIds: string[];
  conversations: Record<string, IConversationItem>;
  lastVisible: Timestamp | null;
  conversationHasMore: boolean;
  conversationLoading: boolean;
  addLoading: boolean;
  room: Partial<IRoomType>;
  messages: Record<string, IMessageType>;
  messageIds: IMessageId[];
  users: Record<string, IUser>;
  loaddingMoreMessages: boolean;
  hasMoreMessages: boolean;
  lastTimeVisitConversation: Date | null;
  firstVisible: Timestamp | null;
}

const initialState: MessageState = {
  // PLOP: Add initial state below -- DO NOT ERASE
  loading: false,
  list: [],
  total: 0,
  page: 0,
  isShowDot: true,
  conversationIds: [],
  conversations: {},
  lastVisible: null,
  conversationHasMore: false,
  conversationLoading: false,
  addLoading: false,
  room: {},
  messages: {},
  messageIds: [],
  users: {},
  loaddingMoreMessages: false,
  hasMoreMessages: false,
  lastTimeVisitConversation: null,
  firstVisible: null,
};

const MessageProvider = createSlice({
  name: 'message',
  initialState,
  reducers: {
    getConversationListRequest(state) {
      state.conversationLoading = true;
      return state;
    },

    getConversationListSuccess(state, action) {
      const { conversationIds, conversations, lastVisible } = action.payload;
      state.conversations = { ...state.conversations, ...conversations };
      state.conversationIds = state.conversationIds.concat(conversationIds);
      state.conversationLoading = false;
      state.lastVisible = lastVisible;
      state.conversationHasMore = conversationIds.length >= CONVERSATION_LIMIT;
      return state;
    },
    getConversationListError(state) {
      state.conversationLoading = false;
      return state;
    },
    listenConversationListRequest(state) {
      return state;
    },
    listenConversationUpdatedRequest(state) {
      return state;
    },
    listenConversationUpdatedSuccess(state, action) {
      const { conversations } = action.payload;
      if (!_isEmpty(conversations)) {
        if (conversations[Object.keys(conversations)[0]].lastMessage.updatedAt) {
          const commingRoomTime = moment(
            conversations[Object.keys(conversations)[0]].lastMessage?.updatedAt.toDate(),
          );
          const lastRoomTime = moment(
            state.conversations[
              state.conversationIds[state.conversationIds.length - 1]
            ].lastMessage.updatedAt?.toDate(),
          );
          // If last message of room < comming room
          if (commingRoomTime.diff(lastRoomTime) > 0) {
            state.conversations = { ...state.conversations, ...conversations };
          } else {
            state.conversationIds = state.conversationIds.filter(
              (conversationId) => conversationId !== Object.keys(conversations)[0],
            );
            delete state.conversations[Object.keys(conversations)[0]];
          }
        }
      }

      return state;
    },
    listenConversationListSuccess(state, action) {
      const { conversationIds, conversations, lastVisible } = action.payload;

      const isFirstListenener = state.conversationIds.length === 0;
      if (isFirstListenener) {
        state.conversationIds = conversationIds;
        state.conversations = conversations;
        state.lastVisible = lastVisible;
        state.conversationHasMore = conversationIds.length >= CONVERSATION_LIMIT;
      } else {
        if (conversationIds.length) {
          // Compare first room and comming room. If comming > first, put it to top
          const commingRoomTime = moment(
            conversations[conversationIds[0]].lastMessage.updatedAt.toDate(),
          );
          const firstRoomTime = moment(
            state.conversations[state.conversationIds[0]].lastMessage.updatedAt?.toDate(),
          );

          if (commingRoomTime.diff(firstRoomTime) > 0) {
            // Remove old conversation in the list
            state.conversationIds = state.conversationIds.filter(
              (conversationId) => !conversationIds.includes(conversationId),
            );
            // put new updated conversation to top
            state.conversationIds = conversationIds.concat(state.conversationIds);
          }
          state.conversations = { ...state.conversations, ...conversations };
        }
      }

      if (conversationIds.length) {
        state.firstVisible = conversations[conversationIds[0]].lastMessage.updatedAt;
      }

      return state;
    },
    listenConversationListRemove(state, action) {
      if (action.payload.isClearList) {
        return initialState;
      }
      state.lastTimeVisitConversation = new Date();
      return state;
    },
    sendMessageRequest(state, action) {
      state.addLoading = true;
      return state;
    },
    sendMessageSuccess(state) {
      state.addLoading = false;
      return state;
    },
    sendMessageFailure(state) {
      state.addLoading = false;
      return state;
    },
    listenRoomRequest(state, action) {
      return state;
    },
    listenRoomSuccess(state, action) {
      state.room = action.payload;
      return state;
    },
    unsubscribeRoom(state) {
      state.users = {};
      state.room = {};
      return state;
    },
    listenMessageRequest(state, action) {
      return state;
    },
    listenMessageSuccess(state, action) {
      const { messageIds = [], messageData = {}, firstFetch } = action.payload;
      const newMessageIds: IMessageId[] = [];
      messageIds.forEach((messageId: IMessageId) => {
        if (!state.messages[messageId.id]) {
          newMessageIds.push(messageId);
        }
      });
      const oldestMessageId = state.messageIds[state.messageIds.length - 1];
      if (newMessageIds.length) {
        if (!state.messageIds[0]) {
          state.messageIds = newMessageIds.concat(state.messageIds);
        } else {
          if (newMessageIds[0] && messageData[newMessageIds[0].id] && oldestMessageId) {
            const commingMessageTime = moment(messageData[newMessageIds[0].id].createdAt.toDate());
            const oldestMessageTime = moment(state.messages[oldestMessageId.id].createdAt.toDate());
            // Determine the comming message is lastest message or oldest message
            if (oldestMessageTime.diff(commingMessageTime) > 0) {
              state.messageIds = state.messageIds.concat(newMessageIds);
            } else {
              state.messageIds = newMessageIds.concat(state.messageIds);
            }
          }
        }
      }

      state.messages = { ...state.messages, ...messageData };
      // firstFetch is used to show/not show loading more icon at first time
      if (firstFetch) {
        state.hasMoreMessages = newMessageIds.length >= MESSAGE_LIMIT;
      }
      return state;
    },
    unsubscribeMessage(state) {
      state.messageIds = [];
      state.messages = {};
      state.hasMoreMessages = false;
      state.loaddingMoreMessages = false;
      return state;
    },
    getUsersInfoRequest(state, action) {
      return state;
    },
    getUsersInfoSuccess(state, action) {
      state.users = action.payload;
      return state;
    },
    getMoreMessageRequest(state, action) {
      state.loaddingMoreMessages = true;
      return state;
    },
    getMoreMessageSuccess(state, action) {
      const { messageIds = [], messageData = {} } = action.payload;
      state.messages = { ...state.messages, ...messageData };
      state.messageIds = state.messageIds.concat(messageIds);
      state.hasMoreMessages = messageIds.length >= MESSAGE_LIMIT;
      state.loaddingMoreMessages = false;
      return state;
    },
    downLoadFileMessageRequest(state, action) {
      return state;
    },
    readRoomRequest(state, action) {
      return state;
    },
    readRoomSuccess(state, action) {
      const { roomId, senderId } = action.payload;
      if (!_isEmpty(state.conversations)) {
        _set(state, `conversations.${roomId}.numberOfUnreadMessage.${senderId}`, 0);
      }
      return state;
    },
    deleteMessageRequest(state, action) {
      return state;
    },
    deleteMessageSuccess(state, action) {
      state.messageIds = state.messageIds.filter((messageId) => messageId.id !== action.payload);
      delete state.messages[action.payload];
      return state;
    },
  },
});

export const {
  getConversationListRequest,
  getConversationListSuccess,
  getConversationListError,
  listenConversationListRequest,
  listenConversationListSuccess,
  listenConversationListRemove,
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageFailure,
  listenRoomRequest,
  listenRoomSuccess,
  unsubscribeRoom,
  listenMessageRequest,
  listenMessageSuccess,
  unsubscribeMessage,
  getUsersInfoRequest,
  getUsersInfoSuccess,
  getMoreMessageRequest,
  getMoreMessageSuccess,
  downLoadFileMessageRequest,
  readRoomRequest,
  readRoomSuccess,
  deleteMessageRequest,
  deleteMessageSuccess,
  listenConversationUpdatedRequest,
  listenConversationUpdatedSuccess,
} = MessageProvider.actions;

export default MessageProvider.reducer;
