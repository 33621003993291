import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';

class Project extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  list = (params): Promise<AxiosResponse> => {
    return this.get('/booking/operator/projects', { params });
  };

  add = (params): Promise<AxiosResponse> => {
    return this.post('/booking/operator/projects', params);
  };

  detail = (id, params): Promise<AxiosResponse> => {
    return this.get(`/booking/operator/projects/${id}`, { params });
  };

  updateDirectionCost = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/projects/${id}`, params);
  };

  changeStatus = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/projects/${id}/progress-flag`, params);
  };

  addFileToProject = (id, params): Promise<AxiosResponse> => {
    return this.post(`/booking/operator/projects/${id}/documents`, params);
  };

  removeFileFromProject = (id, params): Promise<AxiosResponse> => {
    return this.delete(`/booking/operator/projects/${id}/documents`, {
      data: {
        ...params,
      },
    });
  };

  updateComment = (id, params): Promise<AxiosResponse> => {
    return this.put(`/booking/operator/projects/${id}/comment`, params);
  };

  getDownLoadFile = (id, params): Promise<AxiosResponse> => {
    return this.post(`/booking/operator/projects/${id}/documents/file`, params);
  };
}

const service = new Project('');
export default service;
