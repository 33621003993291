/* eslint-disable max-len */
const TRANSLATIONS_EN = {
  global: {
    yes: 'Yes',
    no: 'No',
  },
  login: {
    login: 'Log in',
  },
  menuBar: {
    dashboard: 'Dashboard',
    bookings: 'Bookings',
    sales: 'Sales',
    providerEarnings: 'Provider Earnings',
    providerPayout: 'Provider Payout',
    providers: 'Service Providers',
    customers: 'Customers',
    services: 'Services',
    support: 'Support',
    userReport: 'User report',
    systemUsers: 'System users',
    users: 'Users',
    roles: 'Roles',
    activityLogs: 'Activity logs',
    settings: 'Settings',
  },
  actionButton: {
    addCoach: 'Add Coach',
    uploadAndContinue: 'UPLOAD AND CONTINUE',
    addFile: 'ADD FILE',
    createAccounts: 'CREATE ACCOUNTS',
    replaceFile: 'Replace file',
    cancel: 'Cancel',
    ok: 'OK',
    createAccount: 'CREATE ACCOUNT',
    changePassword: 'Change password',
    change: 'CHANGE',
    deactivate: 'DEACTIVATE',
    active: 'ACTIVE',
    delete: 'DELETE',
    prev: 'PREV',
    next: 'NEXT',
    showToday: 'Show Today',
    showThisWeek: 'Show this week',
    showThisMonth: 'Show this month',
    showThisCycle: 'Show this cycle',
    view: 'View',
    createNews: 'CREATE NEWS',
  },
  validation: {
    invalidEmail: 'このメールアドレスは無効です',
    invalidUpload: '{{fileName}}は（PNG、JPG、HEIC）ファイルではありません',
  },
  tableForm: {
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: 'Export results (CSV)',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
  },
  loading: {
    loading: 'Loading',
  },
  profile: {
    edit: 'Edit profile',
    fullName: 'Full name',
    accountStatus: 'Account Status',
    lastLogin: 'Last login',
    phoneticName: 'Phonetic Name',
    email: 'Email',
    emailAddress: 'E-mail address',
    birthday: 'Birthday',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    lastName: 'Last name',
    firstName: 'First name',
    phoneticLastName: 'Phonetic last name',
    phoneticFirstName: 'Phonetic first name',
    displayName: 'Display name',
    profilePicture: 'Profile picture',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    unanswered: 'Unanswered',
    registerDate: 'Register date',
    region: 'Region',
    prefecture: 'Prefecture',
    zipCode: 'Zip code',
    businessImages: 'Business images',
    profileText: 'Profile text',
    businessName: 'Business name',
    businessNumber: 'Business number',
    addressLine1: 'Address line 1',
    municipality: 'Municipality',
    addressLine2: 'Address line 2',
    addressPrivacySettings: 'Address privacy settings',
    addressPrivacy: 'Address privacy',
    mapLocation: 'Map location',
    accountHolder: 'Account holder',
    accountNumber: 'Account number',
    accountType: 'Account type',
    bankBookImage: 'Bank book image',
    financialInstitution: 'Financial institution',
    financialInstitutionName: 'Financial institution name',
    bankCode: 'Bank code',
    branchName: 'Branch name',
    branchCode: 'Branch code',
    username: 'Username',
    password: 'Password',
    role: 'Role',
    newPassword: 'New password',
    permissionBooking: 'Bookings',
    permissionProvider: 'Provider management',
    permissionCustomer: 'Customer management',
    permissionService: 'Service management',
    description: 'Description',
  },
  detail: {
    detail: 'Detail',
    paymentType: 'Payment type',
    paymentID: 'Payment ID',
    customerName: 'Customer name',
    totalChargedToCard: 'Total charged to card',
    cardEndingIn: 'Card ending in',
    chargedOn: 'Charged on',
    menuItem: 'Menu item',
    amount: 'Amount',
    totalAmount: 'Total amount',
    subtotal: 'Subtotal',
    discount: 'Discount',
    lastUpdated: 'Last updated',
    description: 'Description',
    servicePhoto: 'Service photo',
    displayOrder: 'Display order',
  },
  action: {
    changeStatus: 'Change status',
    cancel: 'Cancel',
    duplicate: 'Duplicate',
    viewDetails: 'View Details',
  },
  status: {
    pending: 'Pending',
    confirmed: 'Confirmed',
    cancelled: 'Cancelled',
    complete: 'Complete',
    deleted: 'Deleted',
    active: 'Active',
    inActive: 'InActive',
    disabled: 'Disabled',
    newDeleted: 'Deleted',
  },
  title: {
    serviceProviders: 'Service providers',
    profile: 'Profile',
    booking: 'Booking',
    services: 'Services',
    schedule: 'Schedule',
    sales: 'Sales',
    reviews: 'Reviews',
    basicInformation: 'Basic information',
    businessInformation: 'Business information',
    bankInformation: 'Bank information',
    customers: 'Customers',
    genderatedContent: 'Genderated content',
    bookingInformation: 'Booking information',
    paymentInformation: 'Payment information',
    statusHistory: 'Status history',
    requested: 'Requested',
    confirmation: 'Confirmation',
    createService: 'Create service',
    editCategory: 'Edit category',
    usernameAndPassword: 'Username & password',
    systemAccount: 'System accounts',
    role: 'Role',
    changePassword: 'Change password',
    deactivate: 'Deactivate',
    active: 'Active',
    delete: 'Delete',
    nameAndDescription: 'Name and description',
    permission: 'Permissions',
    earnings: 'Earnings',
    currentPayoutCycleEarnings: 'Earnings of current payout cycle',
    historyEarnings: 'History earnings',
    earningDetail: 'Earning details',
    transactions: 'Transactions',
  },
  description: {
    statusHistoryRequested: 'Customer has requested booking',
    statusHistoryConfirmation: 'Waiting for provider confirmation',
    statusHistoryComplete: 'Customer received service',
    changePassword: 'Change password of this account.',
    deactivateAccount: 'The account will be deactivate and user will no longer have access.',
    activeAccount: 'The account will be active and user can access.',
    deleteAccount: 'The account will be deleted.',
    activeRole: 'The role will be active and user can access.',
    deactivateRole: 'The role will be deactivate and user will no longer have access.',
    deleteRole: 'The role will be deleted.',
  },
  tableColumn: {
    view: 'View',
    edit: 'Edit',
    active: 'Active',
    deactive: 'Deactive',
    delete: 'Delete',
  },
  columnTitle: {
    numberId: 'ID #',
    avatar: 'Avatar',
    fullName: 'Full name',
    email: 'Email',
    phoneNumber: 'Phone number',
    registerDate: 'Register date',
    status: 'Status',
    action: 'Action',
    bookingId: 'Booking ID',
    lastUpdate: 'Last update',
    bookingDate: 'Booking date',
    provider: 'Provider',
    customer: 'Customer',
    total: 'Total',
    categoryName: 'Category name',
    serviceName: 'Service name',
    duration: 'Duration',
    price: 'Price',
    category: 'Category',
    tags: 'Tags',
    providerName: 'Provider',
    datePayout: 'Date',
    fromDate: 'From date',
    toDate: 'To date',
    totalGross: 'Total gross',
    totalFee: 'Total fee',
    totalNet: 'Total net',
    name: 'Name',
    role: 'Role',
    description: 'Description',
    activityTime: 'Time',
    message: 'Message',
    triggeredBy: 'Triggered by',
    earningGross: 'Earnings gross',
    earningNet: 'Earnings net',
    from: 'From',
    to: 'To',
    date: 'Date',
    transactionId: 'Transaction ID',
    transactionDate: 'Date',
    amount: 'Amount',
    amountNet: 'Amount net',
    title: 'Title',
    createdDateTime: 'Created date',
    publishDateTime: 'Publish date',
  },
  extraContent: {
    importCSV: 'Import (CSV)',
    exportAllCSV: 'Export all (CSV)',
    createUser: 'Create user',
    createBooking: 'Create booking',
    createCategory: 'Create category',
    addService: 'Add service',
    createRole: 'Create role',
  },
  placeholder: {
    searchDefault: 'Search user name, ID, registerd email',
    searchBooking: 'Search Booking ID, Customer, Provider',
    searchService: 'Search service name',
    searchProviderEarning: 'Search by provider email, ID, user name, phone',
    searchPayout: 'Search Payout By Id, Name',
  },
  type: {
    protect: 'Protect',
    public: 'Public',
    saving: 'Saving',
    standard: 'Standard',
  },
  importCSVModal: {
    title: 'Create provider via import CSV',
    download: 'Download a',
    sampleCSVTemplate: 'sample CSV template',
    seeExample: 'to see example.',
    dragDropCSV: 'Drag drop CSV file here',
    overwriteDuplicateEmail: 'Overwrite any accounts with duplicate email address.',
    addedFile: 'Added file',
    importedCSVNotification: `Creating multiple provider accounts, please preview to make sure contents look right, 
      If not you can adjust here.`,
    youWillImport: 'You will be importing',
    numberAccount: 'accounts',
    withTotal: 'with total of etc etc copy text about file details goes here.',
    importingDataNotificationTitle: 'Current importing data to provider lists in database',
    importingDataNotificationContent: `This could task some time to complete. If you'd like, 
    you can close this dialog box and continue working.`,
    closeImportCSVTitle: 'Close Import CSV popup?',
    closeImportCSVContent: 'Do you want to cancel the import CSV.',
  },
  providerConfirmModal: {
    disabled: 'Do you want to disable this provider account?',
    active: 'Do you want to enable this provider account?',
    delete: 'Do you want to delete this provider account?',
  },
  customerConfirmModal: {
    disabled: 'Do you want to disable this customer account?',
    active: 'Do you want to enable this customer account?',
    delete: 'Do you want to delete this customer account?',
  },
  dateViewMode: {
    today: 'Today',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisCycle: 'This cycle',
    customDate: 'Custom date',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    payoutCycle: 'Payout cycle',
    custom: 'Custom',
  },
  changePasswordModal: {
    title: 'Reset your password?',
    content: 'Do you want to reset your password? We will send reset password link to your email.',
    contentForUser:
      "Do you want to reset this user's password? We will send reset password link to this user's email.",
  },
  payout: {
    payout: 'Payout',
    payoutDate: 'Payout date',
    earning: 'Earning',
    commissionFee: 'Commission fee',
    transferFee: 'Transfer fee',
    transferTotal: 'Transfer total',
    providerAddress: 'Provider address',
    staffNumber: 'Staff number',
    chargedBooking: 'Charged booking',
    bankName: 'Bank name',
    bankNumber: 'Bank number',
    bankUserName: "Bank's user name",
    staff: 'Staff',
    chargedDate: 'Charged date',
    date: 'Payout date',
    cycle: 'Payout cycle',
  },
  notification: {
    notificationTitleText: 'NOTIFICATIONS',
    notificationMarkReadText: 'Mark All as Read',
    notificationLoadedText: 'Yay! You have seen it all',
    notificationLoadingText: 'Loading...',
    notificationNoDataText: 'No Data',
  },
  toastrMessage: {
    createSuccess: 'Created successfully',
    updateSuccess: 'Updated successfully',
    deleteSuccess: 'Delete successfully',
  },
  changeStatusServiceModal: {
    titleActive: 'Active this service?',
    titleDeactive: 'Deactive this service',
    contentActive: 'The service will be active',
    contentDeactive: 'The service will be deactive',
  },
  deleteServiceModal: {
    title: 'Delete this service?',
    content: 'Do you want to delete this service',
  },
  common: {
    editAccount: 'Edit account',
    upload: 'Upload',
    createSystemAccount: 'Create system account',
    editSystemAccount: 'Edit system account',
    createRole: 'Create Role',

    booking: 'Booking',
    bookings: 'Bookings',
    order: 'Order',
    orders: 'Orders',
    search: 'Search',
    importCSV: 'Import (CSV)',
    exportCSV: 'Export (CSV)',
    exportAllCSV: 'Export all (CSV)',
    exportResultsCSV: 'Export results (CSV)',
    lastUpdated: 'Last updated',
    provider: 'Provider',
    customer: 'Customer',
    customers: 'Customers',
    total: 'Total',
    status: 'Status',
    action: 'Action',
    view: 'View',
    pending: 'Pending',
    cancelled: 'Cancelled',
    confirmed: 'Confirmed',
    complete: 'Complete',
    changeStatus: 'Change status',
    delete: 'Delete',
    deleted: 'Deleted',
    cancel: 'Cancel',
    duplicate: 'Duplicate',
    viewDetails: 'View Details',
    edit: 'Edit',
    menuItem: 'Menu item',
    duration: 'Duration',
    amount: 'Amount',
    totalAmount: 'Total amount',
    subtotal: 'Subtotal',
    discount: 'Discount',
    statusHistory: 'Status history',
    requested: 'Requested',
    confirmation: 'Confirmation',
    services: 'Services',
    create: 'Create',
    totalChargedToCard: 'Total charged to card',
    cardEndingIn: 'Card ending in',
    chargedOn: 'Charged on',
    min: 'min',
    confirm: 'Confirm',
    ruSure: 'Are you sure?',
    item: 'Item',
    items: 'Items',
    displayName: 'Display name',
    name: 'Name',
    emailAddress: 'Email address',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    lastLogin: 'Last login',
    active: 'Active',
    disabled: 'Disabled',
    fullName: 'Full name',
    tags: 'Tags',
    profile: 'Profile',
    generatedContent: 'Generated content',
    reviews: 'Reviews',
    basicInfo: 'Basic information',
    phoneticName: 'Phonetic name',
    email: 'Email',
    birthday: 'Birthday',
    gender: 'Gender',
    registerDate: 'Register date',
    region: 'Region',
    prefecture: 'Prefecture',
    zipCode: 'Zip code',
    lastName: 'Last name',
    firstName: 'First name',
    phoneticLastName: 'Phonetic last name',
    phoneticFirstName: 'Phonetic first name',
    profilePicture: 'Profile picture',
    optional: 'Optional',
    male: 'Male',
    female: 'Female',
    unanswered: 'Unanswered',
    disable: 'Disable',
    enable: 'Enable',
    editProfile: 'Edit profile',
    serviceProviders: 'Service providers',
    schedule: 'Schedule',
    earnings: 'Earnings',
    public: 'Public',
    protect: 'Protect',
    standard: 'Standard',
    saving: 'Saving',
    profileText: 'Profile text',
    city: 'city',
    mapLocation: 'Map location',
    financialInstitution: 'Financial institution',
    financialInstitutionName: 'Financial institution name',
    date: 'Date',
    from: 'From',
    to: 'To',
    grossAmount: 'Gross amount',
    totalFee: 'Total fee',
    netAmount: 'Net amount',
    description: 'Description',
    price: 'Price',
    category: 'Category',
    displayOrder: 'Display order',
    transactions: 'Transactions',
    time: 'Time',
    message: 'Message',
    triggeredBy: 'Triggered by',
    operator: 'Operator',
    admin: 'Admin',
    user: 'User',
    activityLogs: 'Activity logs',
    role: 'Role',
    roles: 'Roles',
    activate: 'Activate',
    deactivate: 'Deactivate',
    prohibited: 'Prohibited',
    all: 'All',
    nameAndDescription: 'Name and description',
    permissions: 'Permissions',
    systemAccounts: 'System accounts',
    changePass: 'Change password',
    change: 'Change',
    username: 'Username',
    password: 'Password',
    usernameAndPassword: 'Username and password',
    users: 'Users',
    systemUsers: 'System users',
    categories: 'Categories',
    uploadAndContinue: 'Upload and continue',
    or: 'Or',
    totalGross: 'Total gross',
    totalNet: 'Total net',
    providerEarnings: 'Provider earnings',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    payoutCycle: 'Payout cycle',
    custom: 'Custom',
    today: 'Today',
    prev: 'Prev',
    next: 'Next',
    customDate: 'Custom date',
    earning: 'Earning',
    commissionFee: 'Commission fee',
    choice: 'Choice',
    staff: 'Staff',
    createNewAccount: 'Create new account',
    logoutText: 'Logout',
    changePasswordText: 'Change password',
  },
  booking: {
    create: 'Create booking',
    searchPlaceholder: 'Search Booking ID, Customer, Provider',
    ID: 'Booking ID',
    date: 'Booking date',
    info: 'Booking information',
    statusHistoryRequested: 'Customer has requested booking',
    statusHistoryConfirmation: 'Waiting for provider confirmation',
    statusHistoryComplete: 'Customer received service',
  },
  payment: {
    info: 'Payment information',
    type: 'Payment type',
    ID: 'Payment ID',
    cycle: 'Payment cycle',
  },
  customer: {
    management: 'Customer management',
    name: 'Customer name',
    searchPlaceholder: 'Search user name, ID, username, registered email',
    disableConfirmContent: 'Do you want to disable this customer account?',
    enableConfirmContent: 'Do you want to enable this customer account?',
    deleteConfirmContent: 'Do you want to delete this customer account?',
  },
  provider: {
    management: 'Provider management',
    disableConfirmContent: 'Do you want to disable this provider account?',
    enableConfirmContent: 'Do you want to enable this provider account?',
    deleteConfirmContent: 'Do you want to delete this provider account?',
    payout: 'Provider payout',
    address: 'Provider address',
  },
  service: {
    services: 'Services',
    addService: 'Add service',
    management: 'Service management',
    add: 'Add service',
    create: 'Create service',
    edit: 'Edit service',
    name: 'Service name',
    photo: 'Service photo',
    activateModalTitle: 'Activate this service?',
    activateModalContent: 'The service will be active',
    deactivateModalTitle: 'Deactivate this service?',
    deactivateModalContent: 'The service will be deactivated',
    deleteModalTitle: 'Delete this service?',
    deleteModalContent: 'Do you want to delete this service?',
  },
  user: {
    create: 'Create user',
    changePassCardContent: 'Change password of this account.',
    changePassModalTitle: 'Reset your password?',
    changePassModalContent:
      'Do you want to reset your password? We will send reset password link to your email.',
    activateCardContent: 'The account will be active and you can access.',
    activateModalTitle: 'Activate this account?',
    activateModalContent: 'Do you want to activate this role?',
    deactivateCardContent: 'The account will be deactived and you will no longer have access.',
    deactivateModalTitle: 'Deactivate this account?',
    deactivateModalContent: 'Do you want to deactivate this role?',
    deleteCardContent: 'The role will be deleted.',
    deleteModalTitle: 'Delete this account?',
    deleteModalContent: 'Do you want to delete this account?',
  },
  account: {
    status: 'Account status',
    createNew: 'Create new account',
    holder: 'Account holder',
    number: 'Account number',
    type: 'Account type',
  },
  business: {
    info: 'Business information',
    images: 'Business images',
    name: 'Business name',
    number: 'Business number',
  },
  address: {
    line1: 'Address line 1',
    line2: 'Address line 2',
    privacySettings: 'Address privacy settings',
  },
  bank: {
    info: 'Bank information',
    code: 'Bank code',
    bookImage: 'Bank book image',
    name: 'Bank name',
    number: 'Bank number',
    username: 'Bank username',
  },
  branch: {
    name: 'Branch name',
    code: 'Branch code',
  },
  transaction: {
    ID: 'Transaction ID',
  },
  earning: {
    details: 'Earning details',
  },
  earnings: {
    gross: 'Gross earnings',
    net: 'Net earnings',
    currentPayoutCycle: 'Earnings of current payout cycle',
    history: 'History earnings',
  },
  edit: {
    basicInfo: 'Edit basic information',
    businessInfo: 'Edit business information',
    bankInfo: 'Edit bank information',
    systemAccount: 'Edit system account',
    category: 'Edit category',
  },
  create: {
    provider: 'Create provider',
    providerViaImportCSV: 'Create provider via import CSV',
    customerViaImportCSV: 'Create customer via import CSV',
    role: 'Create role',
    account: 'Create account',
    accounts: 'Create accounts',
    systemAccount: 'Create system account',
    category: 'Create category',
    viaImportCSV: {
      downloadSampleCSVTemplate: 'Download a <1>sample CSV template</1> to see example.',
      dragDropCSVFile: 'Drag drop CSV file here',
      overwriteAccountDuplicateEmail: 'Overwrite any accounts with duplicate email address.',
      uploadedContent:
        'You will be importing <1>{{total}} accounts</1> with total of etc etc copy text about file details goes here.',
      creatingContent:
        "This could take some time to complete. If you'd like, you can close this dialog box and continue working.",
      provider: {
        uploadedTitle:
          'Creating multiple provider accounts, please preview to make sure contents look right. If not you can adjust here.',
        creatingTitle: 'Current importing data to provider lists in database',
      },
      customer: {
        uploadedTitle:
          'Creating multiple customer accounts, please preview to make sure contents look right. If not you can adjust here.',
        creatingTitle: 'Current importing data to customer lists in database',
      },
    },
  },
  role: {
    name: 'Role name',
    description: 'Role description',
    activateCardContent: 'The role will be active and user can access.',
    activateModalTitle: 'Activate this role?',
    activateModalContent: 'Do you want to activate this role?',
    deactivateCardContent: 'The role will be deactivated and user will no longer have access.',
    deactivateModalTitle: 'Deactivate this role?',
    deactivateModalContent: 'Do you want to deactivate this role?',
    deleteCardContent: 'The role will be deleted.',
    deleteModalTitle: 'Delete this role?',
    deleteModalContent: 'Do you want to delete this role?',
    administrator: 'Administrator',
    custom: 'Custom',
    provider: 'Provider',
  },
  category: {
    name: 'Category name',
  },
  add: {
    category: 'Add category',
    file: 'Add file',
    service: 'Add service',
  },
  replace: {
    file: 'Replace file',
  },
  show: {
    today: 'Show today',
    thisWeek: 'Show this week',
    thisMonth: 'Show this month',
    thisCycle: 'Show this cycle',
  },
  this: {
    week: 'This week',
    month: 'This month',
    cycle: 'This cycle',
  },
  transfer: {
    fee: 'Transfer fee',
    total: 'Transfer total',
  },
  mark: {
    asCompleted: 'Mark as completed',
  },
  charged: {
    date: 'Charged date',
    booking: 'Charged booking',
  },
  staff: {
    number: 'Staff number',
  },
  newsStatus: {
    inActive: 'INACTIVE',
    active: 'ACTIVE',
    deleted: 'DELETED',
  },
};

export default TRANSLATIONS_EN;
