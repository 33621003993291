import { AxiosResponse } from 'axios';
import ApiInstance from 'core/utils/api';

class AdminUser extends ApiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  list = (params): Promise<AxiosResponse> => {
    return this.get('/accounts/operator/operators', { params });
  };

  add = (params): Promise<AxiosResponse> => {
    return this.post('/accounts/operator/operators', params);
  };

  edit = (id, params): Promise<AxiosResponse> => {
    return this.patch(`/accounts/operator/operators/${id}`, params);
  };

  changeStatus = (id, params): Promise<AxiosResponse> => {
    return this.put(`/accounts/operator/operators/${id}/status`, params);
  };
}

const service = new AdminUser('');
export default service;
